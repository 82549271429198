import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, TextInput, NumberInput, Select, Loader, MultiSelect, Tooltip } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import LoadingWrapper from '../../Atoms/Loading';
import { GlobalContext } from '../../Home/GlobalContext';
import AuthContext from '../../Home/AuthContext';
import uniqueMaterials from '../../../util/GetUniqueValues';
import checkDisabled from '../../../util/checkDisabled';
import dayjs from 'dayjs';

const Qualifications = ({ initialRowData, initialMethods, initialMaterials, initialWorkers, workerId, saveQualificationsCallback }) => {
    // Dictionary to map field names to display names
    const fieldDisplayNames = {
        worker_id: 'Hegesztő',
        method_id: 'Eljárás',
        weld_types: 'Hegesztési típusok',
        positions: 'Pozíció',
        materials: 'Alapanyagok',
        lower_d: 'Külső átmérőtől',
        upper_d: 'Külső átmérőig',
        lower_v: 'Falvastagságtól',
        upper_v: 'Falvastagságig',
        valid_from: 'Érvényes ettől',
        valid_to: 'Érvényes eddig',
        weld_materials: 'Hegesztési anyagok',
        description: 'Leírás'
    };

    // filter initialRowData by workerId
    if (workerId) {
        initialRowData = initialRowData.filter(row => row.worker_id === workerId);
    }
    const [rowData, setRowData] = useState(initialRowData || []);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowData, setCurrentRowData] = useState({});
    const [editingName, setEditingName] = useState('');
    const [methods, setMethods] = useState(initialMethods || []);
    const [materials, setMaterials] = useState(initialMaterials || ['']);
    const [workers, setWorkers] = useState(initialWorkers || []);
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [saveTooltip, setSaveTooltip] = useState('');

    const { projectIDGlobal, projectSettings } = useContext(GlobalContext);
    const { user } = useContext(AuthContext);

    const gridRef = useRef();

    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshCells({ force: true, columns: ['worker_id', 'method_id'] });
        }
    }, [methods, workers]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (!initialRowData) {
            setLoading(true);
            let url = `${apiUrl}/api/qualifications?project_id=${projectIDGlobal}&user_id=${user.userId}`;
            if (workerId) {
                url += `&worker_id=${workerId}`;
            }
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    const parsedData = JSON.parse(data);
                    //cast valid dates to dayjs objects
                    parsedData.forEach(row => {
                        row.valid_from = dayjs(row.valid_from);
                        row.valid_to = dayjs(row.valid_to);
                    });
                    setRowData(parsedData);
                    setLoading(false);
                });
        }
    }, [projectIDGlobal, initialRowData, initialMethods, workerId]);

    useEffect(() => {
        if (!initialMethods) {
            fetch(`${apiUrl}/api/methods?project_id=${projectIDGlobal}`)
                .then(response => response.json())
                .then(data => {
                    const parsedData = JSON.parse(data);
                    setMethods(parsedData.map(method => ({
                        value: method.id,
                        label: method.name
                    })));
                });
        }
    }, [projectIDGlobal, initialMethods]);

    useEffect(() => {
        if (!initialWorkers) {
            fetch(`${apiUrl}/api/workforce?project_id=${projectIDGlobal}&user_id=${user.userId}`)
                .then(response => response.json())
                .then(data => {
                    const parsedData = JSON.parse(data.filtered_workers);
                    setWorkers(parsedData.map(worker => ({
                        value: worker.id,
                        label: worker.name + ' | ' + worker.tag
                    })));
                });
        }
    }, [projectIDGlobal, initialWorkers, user]);

    useEffect(() => {
        if (!initialMaterials) {
            fetch(`${apiUrl}/api/materials`)
            .then(response => response.json())
            .then(data => {
              console.log('Materials:', data);
              const materialColumns = projectSettings.anyagminoseg_cols || [''];
              console.log('MaterialColumns:', materialColumns);
              const materialData = uniqueMaterials(data, materialColumns);
              console.log('MaterialData:', materialData);
              setMaterials(materialData.list);
            });
        }
    }, [projectSettings, initialMaterials]);

    const columnDefs = useMemo(() => [
        {
            headerName: 'Műveletek',
            maxWidth: 150,
            editable: false,
            cellRenderer: params => (
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <button onClick={() => editRow(params.data)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faEdit} style={{ fontSize: '1rem' }} />
                    </button>
                    <button onClick={() => deleteRow(params)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
                    </button>
                </div>
            )
        },
        { 
            field: 'worker_id', 
            headerName: fieldDisplayNames['worker_id'], 
            editable: false, 
            valueFormatter: (params) => {
                return workers.find(worker => worker.value === params.value)?.label || '';
            },
            filterValueGetter: (params) => {
                // Return the formatted label for filtering purposes
                return workers.find(worker => worker.value === params.data.worker_id)?.label || '';
            },
            filter: 'agTextColumnFilter' // Use a text filter for matching the formatted label
        },        
        { 
            field: 'method_id', 
            headerName: fieldDisplayNames['method_id'], 
            editable: false, 
            valueFormatter: (params) => {
                return methods.find(method => method.value === params.value)?.label;
            }
        },
        { 
            field: 'weld_types', 
            headerName: fieldDisplayNames['weld_types'], 
            editable: false 
        },
        { 
            field: 'positions', 
            headerName: fieldDisplayNames['positions'], 
            editable: false 
        },
        { 
            field: 'materials', 
            headerName: fieldDisplayNames['materials'], 
            editable: false 
        },
        { 
            field: 'lower_d', 
            headerName: fieldDisplayNames['lower_d'], 
            editable: false 
        },
        { 
            field: 'upper_d', 
            headerName: fieldDisplayNames['upper_d'], 
            editable: false 
        },
        { 
            field: 'lower_v', 
            headerName: fieldDisplayNames['lower_v'], 
            editable: false 
        },
        { 
            field: 'upper_v', 
            headerName: fieldDisplayNames['upper_v'], 
            editable: false 
        },
        {
            field: 'valid_from',
            headerName: fieldDisplayNames['valid_from'],
            editable: false,
            valueFormatter: (params) => dayjs(params.value).format('YYYY-MM-DD')
        },
        {
            field: 'valid_to',
            headerName: fieldDisplayNames['valid_to'],
            editable: false,
            valueFormatter: (params) => dayjs(params.value).format('YYYY-MM-DD')
        }          
    ], [methods, workers]);

    const saveData = () => {
        const gridApi = gridRef.current.api;
        const rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        setLoading(true);
        let url = `${apiUrl}/api/qualifications?project_id=${projectIDGlobal}&user_id=${user.userId}&worker_id=${workerId}`;
        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rowData)
        }).then(response => response.json())
            .then(() => {
                setLoading(false);
                if (saveQualificationsCallback) {
                    saveQualificationsCallback();
                };
            })
            .catch(() => setLoading(false));
    };

    const newRow = () => {
        const newRowData = {
            id: 0,
            worker_id: workerId || '',
            method_id: '',
            weld_types: [],
            positions: [],
            materials: [],
            lower_d: 0,
            upper_d: 0,
            lower_v: 0,
            upper_v: 0,
            valid_from: dayjs(),
            valid_to: '',
            weld_materials: []
        };
        setCurrentRowData(newRowData);
        setEditingName('');
        setIsModalOpen(true);
    };

    const deleteRow = (params) => {
        const gridApi = gridRef.current.api;
        gridApi.applyTransaction({ remove: [params.data] });
        const rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        setRowData(rowData);
    };

    const editRow = (rowData) => {
        rowData.valid_from = rowData.valid_from.toDate();
        rowData.valid_to = rowData.valid_to.toDate();
        setCurrentRowData(rowData);
        setEditingName(rowData.worker_id);
        setIsModalOpen(true);
    };

    const handleModalChange = (field, value) => {

        if (field === 'valid_from' || field === 'valid_to') {
            value = dayjs(value);
        }
        setCurrentRowData({ ...currentRowData, [field]: value });
    };

    const saveModalData = () => {
        if (editingName === '') {
            setRowData([...rowData, currentRowData]);
        } else {
            const updatedRowData = rowData.map(row => {
                if (row.worker_id === editingName) {
                    return currentRowData;
                }
                return row;
            });
            setRowData(updatedRowData);
        }

        setIsModalOpen(false);
    };

    useEffect(() => {
        console.log('isMultiSelectOpen:', isMultiSelectOpen);
    }, [isMultiSelectOpen]);

    const closeModal = () => {
        console.log('modal close')
        console.log('isMultiSelectOpen:', isMultiSelectOpen);
        if (!isMultiSelectOpen) {
            setIsModalOpen(false);
        }
    };

    useEffect(() => {

        const requiredFields = [
            'worker_id',
            'method_id',
            'weld_types',
            'positions',
            'materials',
            'lower_d',
            'upper_d',
            'lower_v',
            'upper_v',
            'valid_from',
            'valid_to',
            'weld_materials'
        ];

        const { isDisabled, toolTip } = checkDisabled(currentRowData, requiredFields, fieldDisplayNames);
        setSaveDisabled(isDisabled);
        setSaveTooltip(toolTip);
    }, [currentRowData]);
    

    useEffect(() => {
        console.log('currentRowData:', currentRowData);
        console.log('workers:', workers);
        console.log('methods:', methods);
        console.log('materials:', materials);
    }, [currentRowData]);

    return (
        <div className="qualifications-container" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                `}
            </style>
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '10px', gap: '5px' }}>
                <Button
                    onClick={saveData}
                    style={{ padding: '10px', marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
                >
                    Mentés
                </Button>
                <Button
                    onClick={newRow}
                    style={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}
                >
                    Új sor hozzáadása
                </Button>
            </div>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        editType='modal'
                        gridOptions={{
                            singleClickEdit: true
                        }}
                        defaultColDef={{
                            flex: 1,
                            cellStyle: () => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }),
                        }}
                    />
                </LoadingWrapper>
            </div>

            <Modal
                opened={isModalOpen}
                onClose={closeModal}
                closeOnClickOutside={closeModal}
                title="Módosítás"
            >
                <Select
                    label={fieldDisplayNames['worker_id']}
                    data={workers}
                    value={currentRowData.worker_id || ''}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('worker_id', value)}
                    required
                    disabled={!!workerId} 
                    searchable
                />
                <Select
                    label={fieldDisplayNames['method_id']}
                    data={methods}
                    value={currentRowData.method_id || ''}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('method_id', value)}
                    required
                    searchable
                />
                <MultiSelect
                    label={fieldDisplayNames['weld_types']}
                    data={['BW', 'FW']}
                    value={currentRowData.weld_types || []}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('weld_types', value)}
                    required
                    searchable
                />
                <MultiSelect
                    label={fieldDisplayNames['positions']}
                    data={['PA', 'PB', 'PD', 'PH']}
                    value={currentRowData.positions || []}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('positions', value)}
                    required
                    searchable
                />
                <MultiSelect
                    label={fieldDisplayNames['materials']}
                    data={materials}
                    value={currentRowData.materials || ''}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('materials', value)}
                    required
                    searchable
                />
                <MultiSelect
                    label={fieldDisplayNames['weld_materials']}
                    data={['FM1', 'FM2', 'FM3', 'FM4', 'FM5', 'FM6']}
                    value={currentRowData.weld_materials || []}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('weld_materials', value)}
                    required
                    searchable
                />
                <div style={{ display: 'flex', gap: '10px' }}>
                    <NumberInput
                        label={fieldDisplayNames['lower_d']}
                        value={currentRowData.lower_d}
                        onChange={(value) => handleModalChange('lower_d', value)}
                        required
                        error={currentRowData.upper_d < currentRowData.lower_d && 'A külső átmérőtől kisebb, mint a külső átmérőig'}
                    />
                    <NumberInput
                        label={fieldDisplayNames['upper_d']}
                        value={currentRowData.upper_d}
                        onChange={(value) => handleModalChange('upper_d', value)}
                        required
                        error={currentRowData.upper_d < currentRowData.lower_d && 'A külső átmérőig nagyobb, mint a külső átmérőtől'}
                    />
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <NumberInput
                        label={fieldDisplayNames['lower_v']}
                        value={currentRowData.lower_v}
                        onChange={(value) => handleModalChange('lower_v', value)}
                        required
                        error={currentRowData.upper_v < currentRowData.lower_v && 'A falvastagságtól kisebb, mint a falvastagságig'}
                    />
                    <NumberInput
                        label={fieldDisplayNames['upper_v']}
                        value={currentRowData.upper_v}
                        onChange={(value) => handleModalChange('upper_v', value)}
                        required
                        error={currentRowData.upper_v < currentRowData.lower_v && 'A falvastagságig nagyobb, mint a falvastagságtól'}
                    />
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <DateInput
                        label={fieldDisplayNames['valid_from']}
                        value={currentRowData.valid_from}
                        onChange={(value) => handleModalChange('valid_from', value)}
                        defaultLevel='decade'
                        valueFormat='YYYY-MM-DD'
                        required
                    />
                    <DateInput
                        label={fieldDisplayNames['valid_to']}
                        value={currentRowData.valid_to}
                        onChange={(value) => handleModalChange('valid_to', value)}
                        defaultLevel='decade'
                        valueFormat='YYYY-MM-DD'
                        required
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Button onClick={closeModal}>
                        Mégse
                    </Button>
                    <Tooltip
                        label={saveTooltip}
                        disabled={!saveDisabled}
                        withinPortal
                        withArrow
                    >
                        <div>
                            <Button onClick={saveModalData} disabled={saveDisabled}>
                                Mentés
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            </Modal>

        </div>
    );
};

export default Qualifications;
