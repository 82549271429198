import { Tabs, rem } from '@mantine/core';
import {
    IconListNumbers,
    IconBuildingBridge2,
    IconEscalator,
    IconSparkles,
    IconTool,
    IconScaleOutline
    
} from '@tabler/icons-react';
import CsotartoTrackingTable from './Tabs/CsotartoTrackingTable';
import CsovezetekTrackingTable from './Tabs/CsovezetekTrackingTable';
import HegesztesTrackingTable from './Tabs/HegesztesTrackingTable';
import KarimaszerelesTrackingTable from './Tabs/KarimaszerelesTrackingTable';
import NyomasprobaTrackingTable from './Tabs/NyomasprobaTrackingTable';

function TrackingTabs( {selectedSheet} ) {
  const iconStyle = { width: rem(12), height: rem(12) };

  return (
    <Tabs defaultValue="csotarto">
      <Tabs.List position='apart'>
        <Tabs.Tab value="csotarto" icon={<IconBuildingBridge2 style={iconStyle} />}>
          Csőtartó
        </Tabs.Tab>
        <Tabs.Tab value="csovezetek" icon={<IconEscalator style={iconStyle} />}>
          Csovezeték
        </Tabs.Tab>
        <Tabs.Tab value="hegesztes" icon={<IconSparkles style={iconStyle} />}>
          Hegesztés
        </Tabs.Tab>
        <Tabs.Tab value="karimaszereles" icon={<IconTool style={iconStyle} />}>
          Karimaszerelés
        </Tabs.Tab>
        <Tabs.Tab value="nyomasproba" icon={<IconScaleOutline style={iconStyle} />}>
          Nyomáspróba
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="csotarto">
        <CsotartoTrackingTable selectedSheet={selectedSheet} />
      </Tabs.Panel>
      <Tabs.Panel value="csovezetek">
        <CsovezetekTrackingTable selectedSheet={selectedSheet} />
      </Tabs.Panel>
      <Tabs.Panel value="hegesztes">
        <HegesztesTrackingTable selectedSheet={selectedSheet} />
      </Tabs.Panel>
      <Tabs.Panel value="karimaszereles">
        <KarimaszerelesTrackingTable selectedSheet={selectedSheet} />
      </Tabs.Panel>
      <Tabs.Panel value="nyomasproba">
        <NyomasprobaTrackingTable selectedSheet={selectedSheet} />
      </Tabs.Panel>
    </Tabs>
  );
}

export default TrackingTabs;
