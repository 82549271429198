import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './component/Login/Login';
import ProtectedRoute from './component/Home/ProtectedRoute';
import { AuthProvider } from './component/Home/AuthContext';
import ProjectSelector from './component/ProjectSelector';
import Analytics from './component/Project/Analytics/Analytics';
import Queries from './component/Project/Queries/ProjectQueries';
import DataEntry from './component/Project/DataEntry/ProjectDataEntry';
import Tracking from './component/Project/Tracking/ProjectTracking';
import Workforce from './component/Project/Workforce/ProjectWorkforce';
import ProjectLayout from './component/Project/ProjectLayout';
import { GlobalProvider } from './component/Home/GlobalContext';
import Layout from './component/Home/Layout';
import Users from './component/Project/Users/Users';
import SCurvePlotter from './component/Project/Analytics/SCurveTester';
import AnalyticsProvider from './component/Project/Analytics/AnalyticsContext';
import ProjectSettings from './component/Project/Settings/ProjectSettings';
import Methods from './component/Project/Methods/Methods';
import Wps from './component/Project/Wps/Wps';
import Qualifications from './component/Project/Qualifications/Qualifications';
import MobilePWAPrompt from './component/Mobile/MobileWrapper';
import MobileRedirect from './component/MobileWeb/MobileRedirect';
import MobileTracking from './component/Mobile/MobileTracking';

function App() {
  return (
    <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh', maxHeight: '100vh' }}>
      <Router>
        <AuthProvider>
            <GlobalProvider>
              <Layout>
                <Routes>
                  <Route path="/" element={<ProtectedRoute> <MobileRedirect /> </ProtectedRoute>} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/no-access" element={<div>Access denied</div>} />
                  <Route path="/project-selection" element={<ProtectedRoute><ProjectSelector /></ProtectedRoute>} />
                  <Route path="/mobile" element={<ProtectedRoute> <MobilePWAPrompt /> </ProtectedRoute>} />
                  <Route path="/mobile/project-selection" element={<ProtectedRoute><ProjectSelector /></ProtectedRoute>} />
                  <Route path="/mobile/project/:id/*" element={
                    <ProtectedRoute>
                      <Routes>
                        <Route path="tracking" element={<ProtectedRoute requiredAccess="tracking" redirectPath="/no-access"><MobileTracking /></ProtectedRoute>} />
                      </Routes>
                    </ProtectedRoute>
                  } />
                  <Route path="/project/:id/*" element={
                    <ProtectedRoute>
                      <ProjectLayout>
                        <Routes>
                          <Route path="analytics" element={
                            <ProtectedRoute requiredAccess="analytics" redirectPath="/no-access">
                              <AnalyticsProvider>
                                <Analytics />
                              </AnalyticsProvider>
                            </ProtectedRoute>} />
                          <Route path="data-entry" element={<ProtectedRoute requiredAccess="data_entry" redirectPath="/no-access"><DataEntry /></ProtectedRoute>} />
                          <Route path="tracking" element={<ProtectedRoute requiredAccess="tracking" redirectPath="/no-access"><Tracking /></ProtectedRoute>} />
                          <Route path="workforce" element={<ProtectedRoute requiredAccess="workforce" redirectPath="/no-access"><Workforce /></ProtectedRoute>} />
                          <Route path="qualifications" element={<ProtectedRoute requiredAccess="workforce" redirectPath="/no-access"><Qualifications /></ProtectedRoute>} />
                          <Route path="queries" element={<ProtectedRoute requiredAccess="analytics" redirectPath="/no-access"><Queries /></ProtectedRoute>} />
                          <Route path="users" element={<ProtectedRoute requiredAccess="admin" redirectPath="/no-access"><Users /></ProtectedRoute>} />
                          <Route path="scurve" element={<ProtectedRoute requiredAccess="analytics" redirectPath="/no-access"><SCurvePlotter /></ProtectedRoute>} />
                          <Route path="settings" element={<ProtectedRoute requiredAccess="admin" redirectPath="/no-access"><ProjectSettings /></ProtectedRoute>} />
                          <Route path="methods" element={<ProtectedRoute requiredAccess="data_entry" redirectPath="/no-access"><Methods /></ProtectedRoute>} />
                          <Route path="wps" element={<ProtectedRoute requiredAccess="data_entry" redirectPath="/no-access"><Wps /></ProtectedRoute>} />
                        </Routes>
                      </ProjectLayout>
                    </ProtectedRoute>
                  } />
                </Routes>
              </Layout>
            </GlobalProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
