import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, TextInput, NumberInput, MultiSelect, Select, Loader, Tooltip } from '@mantine/core';
import LoadingWrapper from '../../Atoms/Loading';
import { GlobalContext } from '../../Home/GlobalContext';
import AuthContext from '../../Home/AuthContext';
import uniqueMaterials from '../../../util/GetUniqueValues';
import checkDisabled from '../../../util/checkDisabled';

const Wps = () => {
    // Field display name mappings
    const fieldDisplayNames = {
        name: 'Név',
        material: 'Alapanyag',
        weld_material: 'Hegesztőanyag',
        positions: 'Pozíciók',
        lower_d: 'Átmérőtől',
        upper_d: 'Átmérőig',
        lower_v: 'Falvastagságtól',
        upper_v: 'Falvastagságig',
        method_ids: 'Eljárások',
        description: 'Leírás'
    };

    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowData, setCurrentRowData] = useState({});
    const [editingName, setEditingName] = useState('');
    const [methods, setMethods] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [saveTooltip, setSaveTooltip] = useState('');

    const { projectIDGlobal, projectSettings } = useContext(GlobalContext);
    const { user } = useContext(AuthContext);

    const gridRef = useRef();

    // Refresh when methods come in
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshCells({ force: true, columns: ['method_ids'] });
        }
    }, [methods]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);
        fetch(`${apiUrl}/api/wps?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                setRowData(parsedData);
                setLoading(false);
            });

        fetch(`${apiUrl}/api/methods?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                setMethods(parsedData.map(method => ({
                    value: method.id,
                    label: method.name
                })));
            });
    }, [projectIDGlobal]);

    useEffect(() => {
        fetch(`${apiUrl}/api/materials`)
            .then(response => response.json())
            .then(data => {
                const materialColumns = projectSettings.anyagminoseg_cols;
                const materialData = uniqueMaterials(data, materialColumns);
                setMaterials(materialData.list);
            });
    }, [projectSettings]);

    const columnDefs = useMemo(() => [
        {
            headerName: 'Actions',
            maxWidth: 150,
            editable: false,
            cellRenderer: params => (
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <button onClick={() => editRow(params.data)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faEdit} style={{ fontSize: '1rem' }} />
                    </button>
                    <button onClick={() => deleteRow(params)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
                    </button>
                </div>
            )
        },
        { field: 'name', headerName: fieldDisplayNames['name'], editable: false },
        { field: 'material', headerName: fieldDisplayNames['material'], editable: false },
        { field: 'weld_material', headerName: fieldDisplayNames['weld_material'], editable: false },
        { field: 'positions', headerName: fieldDisplayNames['positions'], editable: false },
        { field: 'lower_d', headerName: fieldDisplayNames['lower_d'], editable: false },
        { field: 'upper_d', headerName: fieldDisplayNames['upper_d'], editable: false },
        { field: 'lower_v', headerName: fieldDisplayNames['lower_v'], editable: false },
        { field: 'upper_v', headerName: fieldDisplayNames['upper_v'], editable: false },
        {
            field: 'method_ids', 
            headerName: fieldDisplayNames['method_ids'], 
            editable: false, 
            valueFormatter: (params) => {
                return params.value.map(id => methods.find(method => method.value === id)?.label).join(', '); 
            }
        },
        { field: 'description', headerName: fieldDisplayNames['description'], editable: false }
    ], [methods]);

    const saveData = () => {
        const gridApi = gridRef.current.api;
        const rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        rowData.forEach(row => row.project_id = projectIDGlobal);
        setLoading(true);

        fetch(`${apiUrl}/api/wps`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rowData)
        }).then(response => response.json())
            .then(() => {
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const newRow = () => {
        const newRowData = {
            id: '',
            name: '',
            material: '',
            positions: [],
            lower_d: 0,
            upper_d: 0,
            lower_v: 0,
            upper_v: 0,
            method_ids: [],
            description: ''
        };
        setCurrentRowData(newRowData);
        setEditingName('');
        setIsModalOpen(true);
    };

    const deleteRow = (params) => {
        const gridApi = gridRef.current.api;
        gridApi.applyTransaction({ remove: [params.data] });
        const rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        setRowData(rowData);
    };

    const editRow = (rowData) => {
        setCurrentRowData(rowData);
        setEditingName(rowData.name);
        setIsModalOpen(true);
    };

    const handleModalChange = (field, value) => {
        setCurrentRowData({ ...currentRowData, [field]: value });
    };

    const isNameUnique = (name, originalName) => {
        return !rowData.some(row => row.name !== originalName && row.name === name);
    };

    const saveModalData = () => {
        if (editingName === '') {
            setRowData([...rowData, currentRowData]);
        } else {
            const updatedRowData = rowData.map(row => {
                if (row.name === editingName) {
                    return currentRowData;
                }
                return row;
            });
            setRowData(updatedRowData);
        }

        setIsModalOpen(false);
    };

    useEffect(() => {

        console.log('currentRowData:', currentRowData);

        const requiredFields = [
            'name',
            'material',
            'positions',
            'lower_d',
            'upper_d',
            'lower_v',
            'upper_v',
            'method_ids'
        ];

        const { isDisabled, toolTip } = checkDisabled(currentRowData, requiredFields, fieldDisplayNames);
        setSaveDisabled(isDisabled);
        setSaveTooltip(toolTip);
    }, [currentRowData]);

    useEffect(() => {
        console.log('isMultiSelectOpen:', isMultiSelectOpen);
    }, [isMultiSelectOpen]);

    const closeModal = () => {
        if (!isMultiSelectOpen) {
            setIsModalOpen(false);
        }
    };

    return (
        <>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                `}
            </style>

            <Button
                onClick={saveData}
                style={{ padding: '10px', marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
            >
                Mentés
            </Button>
            <Button
                onClick={newRow}
                style={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}
            >
                Új sor hozzáadása
            </Button>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        editType='modal'
                        gridOptions={{
                            singleClickEdit: true
                        }}
                        defaultColDef={{
                            flex: 1,
                            cellStyle: () => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }),
                        }}
                    />
                </LoadingWrapper>
            </div>

            <Modal
                opened={isModalOpen}
                onClose={closeModal}
                closeOnClickOutside={closeModal}
                title="Edit Row"
            >
                <TextInput
                    label={fieldDisplayNames['name']}
                    value={currentRowData.name || ''}
                    onChange={(event) => handleModalChange('name', event.currentTarget.value)}
                    required
                    error={!isNameUnique(currentRowData.name, editingName) && 'A név már létezik'}
                />
                <Select
                    label={fieldDisplayNames['material']}
                    data={materials}
                    value={currentRowData.material || ''}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('material', value)}
                    required
                    searchable
                />
                <Select
                    label={fieldDisplayNames['weld_material']}
                    data={['FM1', 'FM2', 'FM3', 'FM4', 'FM5', 'FM6']}
                    value={currentRowData.weld_material || ''}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('weld_material', value)}
                    required
                    searchable
                />
                <MultiSelect
                    label={fieldDisplayNames['positions']}
                    data={['PA', 'PB', 'PD', 'PH']}
                    value={currentRowData.positions || []}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('positions', value)}
                    required
                    searchable
                />
                <div style={{ display: 'flex', gap: '10px' }}>
                    <NumberInput
                        label={fieldDisplayNames['lower_d']}
                        value={currentRowData.lower_d}
                        onChange={(value) => handleModalChange('lower_d', value)}
                        required
                        error={currentRowData.upper_d < currentRowData.lower_d && 'Átmérőtől must be less than Átmérőig'}
                    />
                    <NumberInput
                        label={fieldDisplayNames['upper_d']}
                        value={currentRowData.upper_d}
                        onChange={(value) => handleModalChange('upper_d', value)}
                        required
                        error={currentRowData.upper_d < currentRowData.lower_d && 'Átmérőig must be greater than Átmérőtől'}
                    />
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <NumberInput
                        label={fieldDisplayNames['lower_v']}
                        value={currentRowData.lower_v}
                        onChange={(value) => handleModalChange('lower_v', value)}
                        required
                        error={currentRowData.upper_v < currentRowData.lower_v && 'Falvastagságtól must be less than Falvastagságig'}
                    />
                    <NumberInput
                        label={fieldDisplayNames['upper_v']}
                        value={currentRowData.upper_v}
                        onChange={(value) => handleModalChange('upper_v', value)}
                        required
                        error={currentRowData.upper_v < currentRowData.lower_v && 'Falvastagságig must be greater than Falvastagságtól'}
                    />
                </div>
                <MultiSelect
                    label={fieldDisplayNames['method_ids']}
                    data={methods}
                    value={currentRowData.method_ids || []}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                        setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('method_ids', value)}
                    required
                    searchable
                />
                <TextInput
                    label={fieldDisplayNames['description']}
                    value={currentRowData.description || ''}
                    onChange={(event) => handleModalChange('description', event.currentTarget.value)}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Button onClick={closeModal}>
                        Mégse
                    </Button>
                    <Tooltip
                        label={saveTooltip}
                        disabled={!saveDisabled}
                        withinPortal
                        withArrow
                    >
                        <div>
                            <Button onClick={saveModalData} disabled={saveDisabled}>
                                Mentés
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            </Modal>

        </>
    );
};

export default Wps;
