import React, { useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IconCheckbox, IconEdit, IconChartArea, IconTableExport, IconUser, IconHammer, IconCertificate, IconSettings, IconLetterE, IconLetterW } from '@tabler/icons-react';
import { GlobalContext } from '../Home/GlobalContext';

const tabs = {
    main: [
        { link: (id) => `/project/${id}/analytics`, label: 'Analitikák', icon: <IconChartArea />, permission: 'analytics' },
        { link: (id) => `/project/${id}/queries`, label: 'Lekérdezések', icon: <IconTableExport />, permission: 'analytics' },
        { link: (id) => `/project/${id}/data-entry`, label: 'Adatbevitel', icon: <IconEdit />, permission: 'data_entry' },
        { link: (id) => `/project/${id}/tracking`, label: 'Nyomonkövetés', icon: <IconCheckbox />, permission: 'tracking' },
        { link: (id) => `/project/${id}/workforce`, label: 'Hegesztők', icon: <IconHammer />, permission: 'workforce' },
        { link: (id) => `/project/${id}/qualifications`, label: 'Engedélyek', icon: <IconCertificate />, permission: 'workforce' },
        { link: (id) => `/project/${id}/methods`, label: 'Eljárások', icon: <IconLetterE />, permission: 'data_entry' },
        { link: (id) => `/project/${id}/wps`, label: 'WPS', icon: <IconLetterW />, permission: 'data_entry' },
        { link: (id) => `/project/${id}/users`, label: 'Felhasználók', icon: <IconUser />, permission: 'analytics' },
        { link: (id) => `/project/${id}/settings`, label: 'Projekt beállítások', icon: <IconSettings />, permission: 'data_entry' },
    ],
};

const NavigationRibbon = () => {
    const params = useParams();
    const { permissionsGlobal } = useContext(GlobalContext);
    const [active, setActive] = useState('Analitikák');
    const [isExpanded, setIsExpanded] = useState(false); // State to control expanded/collapsed state

    if (!permissionsGlobal) {
        return null; // or a loading spinner
    }

    const links = tabs.main
        .filter(item => permissionsGlobal[item.permission])
        .map(item => (
            <Link
                to={item.link(params.id)}
                key={item.label}
                onClick={() => setActive(item.label)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    textDecoration: 'none',
                    color: active === item.label ? '#007bff' : '#000',
                    borderRadius: '8px',
                    height: '50px', // Set height for consistency
                    transition: 'background-color 0.3s ease', // Optional transition for hover effects
                }}
            >
                <div style={{
                    minWidth: '30px', // Fixed icon width
                    display: 'flex',
                    justifyContent: 'flex-start', // Align icons to the left
                    alignItems: 'center', // Ensure icons and text are vertically aligned
                }}>
                    {item.icon}
                </div>
                <span style={{
                    opacity: isExpanded ? 1 : 0, // Adjust opacity on expand/collapse
                    overflow: 'hidden',
                    whiteSpace: 'nowrap', // Prevent line break for labels
                    maxWidth: isExpanded ? '200px' : '0', // Smooth transition for label width using max-width trick
                    transition: 'max-width 0.5s ease, opacity 0.2s ease', // Smooth transition on hover
                    marginLeft: '10px', // Space between the icon and the label
                    display: 'inline-block', // To ensure max-width applies properly
                }}>
                    {item.label}
                </span>
            </Link>
        ));

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start', // Align the entire content to the left
                backgroundColor: '#f7f7f7',
                padding: '10px',
                borderRadius: '12px',
                maxWidth: isExpanded ? '220px' : '60px', // Control max-width for smooth transition both ways
                transition: 'max-width 0.6s ease', // Smooth max-width transition for parent div
            }}
            onMouseEnter={() => setIsExpanded(true)} // Set expanded state on hover
            onMouseLeave={() => setIsExpanded(false)} // Reset expanded state on hover out
        >
            {links}
        </div>
    );
}

export default NavigationRibbon;
