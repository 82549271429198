import { Tabs, rem } from '@mantine/core';
import {
    IconListNumbers,
    IconBuildingBridge2,
    IconEscalator,
    IconSparkles,
    IconTool,
    IconScaleOutline
    
} from '@tabler/icons-react';



import SheetSummary from './Tabs/Summary';
import CsotartoDataEntryTable from './Tabs/CsotartoDataEntryTable';
import CsovezetekDataEntryTable from './Tabs/CsovezetekDataEntryTable';
import HegesztesDataEntryTable from './Tabs/HegesztesDataEntryTable';
import KarimaDataEntryTable from './Tabs/KarimaDataEntryTable';


function DataEntryTabs( {selectedSheet} ) {
  const iconStyle = { width: rem(12), height: rem(12) };

  return (
    <Tabs
      defaultValue="summary"
      style = {{height: '100%'}}
    >
      <Tabs.List position='apart'>
        <Tabs.Tab value="summary" icon={<IconListNumbers style={iconStyle} />}>
          Summary
        </Tabs.Tab>
        <Tabs.Tab value="csotarto" icon={<IconBuildingBridge2 style={iconStyle} />}>
          Csotartó
        </Tabs.Tab>
        <Tabs.Tab value="csovezetek" icon={<IconEscalator style={iconStyle} />}>
          Csovezeték
        </Tabs.Tab>
        <Tabs.Tab value="hegesztes" icon={<IconSparkles style={iconStyle} />}>
          Hegesztés
        </Tabs.Tab>
        <Tabs.Tab value="karimaszereles" icon={<IconTool style={iconStyle} />}>
          Karimaszerelés
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="summary">
        <div className="flex justify-between Summary">
          <SheetSummary sheetID={selectedSheet.id} />
        </div>
      </Tabs.Panel>
      <Tabs.Panel value="csotarto">
        <CsotartoDataEntryTable selectedSheet={selectedSheet} />
      </Tabs.Panel>
      <Tabs.Panel value="csovezetek">
        <CsovezetekDataEntryTable selectedSheet={selectedSheet} />
      </Tabs.Panel>
      <Tabs.Panel value="hegesztes">
        <HegesztesDataEntryTable selectedSheet={selectedSheet} />
      </Tabs.Panel>
      <Tabs.Panel value="karimaszereles">
        <KarimaDataEntryTable selectedSheet={selectedSheet} />
      </Tabs.Panel>
    </Tabs>
  );
}

export default DataEntryTabs;
