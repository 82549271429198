import { useState, useEffect, useContext } from 'react';
import { Button, Modal, TextInput, Select } from '@mantine/core';
import { GlobalContext } from '../../../Home/GlobalContext';

import uniqueMaterials from '../../../../util/GetUniqueValues';

const SheetSummary = ({
    sheetID,
  }) => {

    //get project id from global context
    const { projectSettings, subcontractors } = useContext(GlobalContext);
    const subcontractorOptions = subcontractors.map(subcontractor => subcontractor.name);
    const apiUrl = process.env.REACT_APP_API_URL;

    //query materials
    const [materials, setMaterials] = useState([]);

    useEffect(() => {
      fetch(`${apiUrl}/api/materials`)
        .then(response => response.json())
        .then(data => {
          console.log('Materials:', data);
          //get only column in projectSettings.anyagminoseg_cols
          const materialColumns = projectSettings.anyagminoseg_cols;
          const materialData = uniqueMaterials(data, materialColumns);
          setMaterials(materialData.list);
        });
    }, []);

    const [newSheetNumber, setNewSheetNumber] = useState('');
    const [newHeight, setNewHeight] = useState('');
    const [newMaterial, setNewMaterial] = useState('');
    const [newSubcontractor, setNewSubcontractor] = useState('');
    const [newPosition, setNewPosition] = useState('');
    
    const fetchSheetData = () => {

        fetch(`${apiUrl}/api/update_sheet?sheet_id=${sheetID}`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setNewSheetNumber(data.sheet_number);
                setNewHeight(data.height);
                setNewMaterial(data.material);
                setNewPosition(data.position);
                // Ensure subcontractors data is available before using
                if (subcontractors.length > 0) {
                    setNewSubcontractor(findSubcontractorLabel(data.subcontractor_id));
                }
            });
    }

    useEffect(() => {
        fetchSheetData();
    }, []);
    
    // Find subcontractor label from id
    const findSubcontractorLabel = (subcontractorID) => {
        const subcontractor = subcontractors.find(subcontractor => subcontractor.id === subcontractorID);
        return subcontractor ? subcontractor.name : '';  // Handle cases where subcontractor is not found
    };
    
  
    const handleUpdateSheet = () => {
        if (newSheetNumber, newHeight, newMaterial, newSubcontractor) {
            // get the id of the selected subcontractor
            const selectedSubcontractor = subcontractors.find(subcontractor => subcontractor.name === newSubcontractor);

            fetch(`${apiUrl}/api/update_sheet?sheet_id=${sheetID}&sheet_number=${newSheetNumber}&height=${newHeight}&material=${newMaterial}&subcontractor=${selectedSubcontractor.id}&position=${newPosition}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(response => response.json())
            .then(data => {});
        };
  };
  return (
    <div className='Summary'>
      <TextInput
        label="Lap száma:"
        value={newSheetNumber}
        onChange={(event) => setNewSheetNumber(event.currentTarget.value)}
      />
      <TextInput
        label="Lap magassága:"
        value={newHeight}
        onChange={(event) => setNewHeight(event.currentTarget.value)}
      />
      <Select
        label="Lap anyaga:"
        data={materials}
        value={newMaterial}
        onChange={(value) => setNewMaterial(value)}
        searchable={true}
        dropdownPosition='bottom'
      />
      <Select
        label="Lap alvállakozó:"
        data={subcontractorOptions}
        value={newSubcontractor}
        onChange={(value) => setNewSubcontractor(value)}
        searchable={true}
        dropdownPosition='bottom'
      />
      <TextInput
        label="Lap pozíció:"
        value={newPosition}
        onChange={(event) => setNewPosition(event.currentTarget.value)}
      />
      <Button className='mt-1' onClick={handleUpdateSheet}>Mentés</Button>
    </div>
    );
};

export default SheetSummary;