import React, { useEffect, useState } from 'react';
import { Card, Modal, Button, TextInput } from '@mantine/core';
import { Paper } from '@mui/material';

// DefaultCard component with optional Delete and Rename buttons
const DefaultCard = ({ item, onSelect, isSelected, onDelete, onRename }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column', // Optional: If you have multiple cards and want them to be in a column
      justifyContent: 'center', // Center horizontally (can be changed to 'flex-start' or 'flex-end' if needed)
      alignItems: 'center', // Center vertically
    }}>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{
          border: '1px solid #ddd',
          padding: '20px',
          marginBottom: '5px',
          borderRadius: '10px',
          cursor: 'pointer',
          transition: 'transform 0.2s, box-shadow 0.2s', // Add transition for box-shadow
          width: '95%',
          willChange: 'transform',
          transform: isSelected ? 'scale(1.05)' : 'none',
          backgroundColor: isSelected ? '#eee' : 'white',
        }}
        onClick={() => onSelect(item.id)}
        onMouseEnter={(e) => {
          if (!isSelected) {
            e.currentTarget.style.transform = 'scale(1.02)';
            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.15)';
          }
        }} // Gentle hover effect
        onMouseLeave={(e) => {
          if (!isSelected) {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = 'none';
          }
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {item.name}
          <div style={{ display: 'flex', gap: '8px' }}>
            {onRename && (
              <Button size="xs" variant="outline" onClick={(e) => { e.stopPropagation(); onRename(item.id, item.name)}}>
                Átnevezés
              </Button>
            )}
            {onDelete && (
              <Button size="xs" color="red" variant="outline" onClick={(e) => { e.stopPropagation(); onDelete(item.id); }}>
                Törlés
              </Button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

// AddCard component for adding new items
const AddCard = ({ onAdd }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px dashed #888', // Moderate border color
        backgroundColor: '#f9f9f9', // Light background color 
        padding: '20px',
        borderRadius: '10px',
        cursor: 'pointer',
        transition: 'transform 0.2s, box-shadow 0.2s', // Transition for box-shadow
        width: '95%',
        fontSize: '20px', // Slightly larger font size
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
        marginBottom: '20px',
      }}
      onClick={onAdd}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.02)';
        e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.15)';
      }} // Gentle hover effect
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
        e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
      }}
    >
      +
    </div>
  );
};



// Main Cards component
// Main Cards component
const Cards = ({
  initialItems = [], // Add default value to prevent undefined
  title,
  onSelect,
  selectedItem,
  onAdd,
  Card: CustomCard,
  customCardProps = {},
  onDelete,
  onRename
}) => {
  const [items, setItems] = useState(initialItems); // Manage the items state
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [selectedItemForAction, setSelectedItemForAction] = useState(null);
  const [newName, setNewName] = useState('');

  // Reset items when initialItems change
  useEffect(() => {
    setItems(initialItems || []); // Ensure items is always an array
  }, [initialItems]);

  // Handler for confirming deletion
  const handleDelete = () => {
    if (selectedItemForAction) {
      onDelete(selectedItemForAction.id);
      setItems(items.filter(item => item.id !== selectedItemForAction.id)); // Remove the item from the state
    }
    setDeleteModalOpen(false);
  };

  // Handler for confirming renaming
  const handleRename = () => {
    if (selectedItemForAction && newName) {
      setItems(items.map(item => 
        item.id === selectedItemForAction.id ? { ...item, name: newName } : item
      ));
    }
    onRename(selectedItemForAction.id, newName);
    setRenameModalOpen(false);
    setNewName('');
  };

  // Open the delete confirmation modal
  const openDeleteModal = (item) => {
    setSelectedItemForAction(item);
    setDeleteModalOpen(true);
  };

  // Open the rename modal
  const openRenameModal = (item) => {
    setSelectedItemForAction(item);
    setNewName(item.name);
    setRenameModalOpen(true);
  };

  // Filter and sort items based on the search query
  const filteredItems = items.filter(item => 
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const allAreIntegers = filteredItems.every(item => /^\d+$/.test(item.name));

  const sortedItems = filteredItems.sort((a, b) => {
    if (allAreIntegers) {
      return parseInt(a.name) - parseInt(b.name);
    }
    return a.name.localeCompare(b.name);
  });

  return (
    <div
      className='cards-container'
      style={{
        padding: '20px',
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        overflow: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2 style={{ textAlign: 'center', marginBottom: '20px', width: '95%' }}> {title} </h2>
      <input 
        type="text" 
        placeholder="Keresés..." 
        value={searchQuery} 
        onChange={(e) => setSearchQuery(e.target.value)} 
        style={{
          width: '95%',
          height: '40px',
          border: '1px solid gray',
          borderRadius: '10px',
          padding: '10px',
          fontSize: '16px',
          marginBottom: '20px',
         }}
      />
      {onAdd && <AddCard onAdd={onAdd} />}
      <Paper className='cards-container-inner' style={{ overflow: 'auto', width: '100%', height: '100%', paddingTop: '5px'}}>
      {sortedItems.map(item => (
        CustomCard ? (
          <CustomCard 
            key={item.id} 
            item={item} 
            onSelect={(event) => {
              setSearchQuery('');
              onSelect(event);
            }}
            isSelected={selectedItem === item.id}
            onDelete={onDelete  ? () => openDeleteModal(item) : undefined}
            onRename={onRename ? () => openRenameModal(item) : undefined}
            {...customCardProps}
          />
        ) : (
          <DefaultCard 
            key={item.id} 
            item={item} 
            onSelect={(event) => {
              setSearchQuery('');
              onSelect(event);
            }}
            isSelected={selectedItem === item.id}
            onDelete={onDelete ? () => openDeleteModal(item) : undefined}
            onRename={onRename ? () => openRenameModal(item) : undefined}
          />
        )
      ))}
      </Paper>

      {/* Delete Confirmation Modal */}
      <Modal
        opened={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Törlés megerősítése"
      >
        <p>
          Figyelem!
          <br />
          Nem csak ez, hanem minden ehhez kapcsolodó elem törlésre fog kerülni. Biztos benne hogy törölni szeretné?
        </p>
        <Button color="red" onClick={handleDelete}>Törlés</Button>
      </Modal>

      {/* Rename Modal */}
      <Modal
        opened={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
        title="Átnevezés"
      >
        <p style={{fontSize: '14px', marginBottom: '0px'}}>Új név:</p>
        <TextInput 
          value={newName} 
          onChange={(e) => setNewName(e.currentTarget.value)} 
          placeholder="Új név..."
          style={{ marginBottom: '16px' }} 
        />
        <Button onClick={handleRename}>Átnevezés</Button>
      </Modal>
    </div>
  );
};

export default Cards;
