import { useState } from 'react';
import { Button, Modal, TextInput, Select } from '@mantine/core';

const AddRevForm = ({ revs, setRevs, selectedIzometria, setShowRevModal }) => {
    const [newRevName, setNewRevName] = useState('');

    const handleAddRev = () => {
    
        if (newRevName) {
          const apiUrl = process.env.REACT_APP_API_URL;

          fetch(`${apiUrl}/api/revs?izometria_id=${selectedIzometria.id}&rev_name=${newRevName}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => response.json())
          .then(data => {
            if (data.id) {
              setRevs([...revs, { id: data.id, name: data.name, active: data.active }]);
              setShowRevModal(false);
            } else {
              alert(data.error || "Failed to add rev");
            }
          });
        }
      };
  
    return (
      <div>
        <TextInput
          label="Uj revizio szama:"
          value={newRevName}
          onChange={(event) => setNewRevName(event.currentTarget.value)}
        />
        <Button className='mt-1' onClick={handleAddRev}>Add Rev</Button>
      </div>
    );
  };

export default AddRevForm;