function checkDisabled(rowData, requiredFields, fieldDisplayNames = {}) {
    let isDisabled = false;
    let missingFields = [];

    requiredFields.forEach(field => {
        const value = rowData[field];

        // Check if the value is null, undefined, or an empty string or empty array
        if (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)) {
            isDisabled = true;
            // Use the display name if provided, otherwise use the field name
            const displayName = fieldDisplayNames[field] || field;
            missingFields.push(displayName);
        }
    });

    let toolTip = isDisabled
        ? `Töltse ki a következő mezőket: ${missingFields.join(', ')}`
        : '';

    return {
        isDisabled,
        toolTip
    };
}

export default checkDisabled;
