import React, { useContext } from 'react';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import NavigationRibbon from './ProjectNavigation';
import { GlobalContext } from '../Home/GlobalContext';

const ProjectLayout = ({ children }) => {
    const navigate = useNavigate();
    const { projectNameGlobal } = useContext(GlobalContext);

    const buttonStyles = {
        backgroundColor: '#f0f0f0',
        color: 'black',
        border: 'none',
        cursor: 'pointer',
        padding: '5px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '20px',
        width: '100%',
        fontWeight: 'bold',
        letterSpacing: '0.5px', // Adds spacing between letters
    };

    const buttonHoverStyles = {
        backgroundColor: '#bebebe',
    };

    return (
        <div
            className='project-layout'
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'auto',
            }}
        >
            <button
                onClick={() => navigate('/')}
                style={buttonStyles}
                onMouseEnter={(e) => {
                    e.target.style.backgroundColor = buttonHoverStyles.backgroundColor;
                    e.target.style.color = buttonHoverStyles.color;
                }}
                onMouseLeave={(e) => {
                    e.target.style.backgroundColor = buttonStyles.backgroundColor;
                    e.target.style.color = buttonStyles.color;
                }}
            >
                {projectNameGlobal}
            </button>
            <div
                className='project-layout-inner'
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    overflow: 'auto',
                }}
            >
                <NavigationRibbon />
                <div
                    style={{flex:1}}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ProjectLayout;
