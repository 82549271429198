import Navbar from "./Navbar";

const Layout = ({ children }) => {
    return (
      <div className="layout" style={{ height: '100vh', display: 'flex', flexDirection: 'column'}}>
        <Navbar />
        <div className="layout-inner" style={{ flexGrow: 1, overflow: 'auto' }}>
          {children}
        </div>
      </div>
    );
};

export default Layout;
