import React from 'react';
import { Card, Badge, Button } from '@mantine/core';

const RevisonCard = ({ item, onSelect, isSelected, onSetActive, onDelete, onRename }) => {
  // Define the highlight color based on the item's active state
  const highlightColor = item.active ? 'green' : 'orange';

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column', // Optional: If you have multiple cards and want them to be in a column
      justifyContent: 'center', // Center horizontally (can be changed to 'flex-start' or 'flex-end' if needed)
      alignItems: 'center', // Center vertically
    }}>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{
          border: '1px solid #ddd',
          borderColor: highlightColor,
          padding: '20px',
          marginBottom: '5px',
          borderRadius: '10px',
          cursor: 'pointer',
          transition: 'transform 0.2s, box-shadow 0.2s', // Add transition for box-shadow
          width: '90%',
          willChange: 'transform',
          transform: isSelected ? 'scale(1.05)' : 'none',
          backgroundColor: isSelected ? '#eee' : 'white',
        }}
        onClick={() => onSelect(item.id)}
        onMouseEnter={(e) => {
          if (!isSelected) {
            e.currentTarget.style.transform = 'scale(1.02)';
            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.15)';
          }
        }} // Gentle hover effect
        onMouseLeave={(e) => {
          if (!isSelected) {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = 'none';
          }
        }}
      >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{item.name}</span>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Badge color={item.active ? 'teal' : 'red'}>
            {item.active ? 'Aktív' : 'Inaktív'}
          </Badge>
          {!item.active && 
            <Button 
              onClick={(e) => { e.stopPropagation(); onSetActive(item.id); }}
              size='xs'
              variant="outline"
              color={highlightColor}
            >
              Aktiválás
            </Button>
          }
          {onRename && (
            <Button size="xs" variant="outline" onClick={(e) => { e.stopPropagation(); onRename(item.id, item.name)}}>
              Átnevezés
            </Button>
          )}
          {onDelete && (
            <Button size="xs" color="red" variant="outline" onClick={(e) => { e.stopPropagation(); onDelete(item.id); }}>
              Törlés
            </Button>
          )}
        </div>
      </div>
    </Card>
    </div>
  );
}

export default RevisonCard;