import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faClipboard } from '@fortawesome/free-solid-svg-icons'; // Added faClipboard for the new column
import { Button, Modal, TextInput, Select, Loader } from '@mantine/core';
import LoadingWrapper from '../../Atoms/Loading';
import { GlobalContext } from '../../Home/GlobalContext';
import AuthContext from '../../Home/AuthContext';
import uniqueMaterials from '../../../util/GetUniqueValues';
import Qualifications from '../Qualifications/Qualifications';

const Workforce = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subcontractorOptions, setSubcontractorOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowData, setCurrentRowData] = useState({});
    const [editingTag, setEditingTag] = useState('');
    const [hiddenWorkers, setHiddenWorkers] = useState([]); // used for checking tag uniqueness
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false); // State for permission modal
    const [selectedWorker, setSelectedWorker] = useState(null); // State to store selected worker for permission modal
    const [methods, setMethods] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [qualificationsWorkers, setQualificationsWorkers] = useState([]);
    const [triggerQualificationsRefresh, setTriggerQualificationsRefresh] = useState(false);

    const { projectIDGlobal, subcontractors, visibleSubcontractors, projectSettings } = useContext(GlobalContext);
    const { user } = useContext(AuthContext);

    const gridRef = useRef();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        console.log('Selected worker:', selectedWorker);
    }, [selectedWorker]);

    useEffect(() => {
        setLoading(true);
        if (!subcontractors || subcontractors.length === 0) {
            return;
        }
        fetch(`${apiUrl}/api/workforce?project_id=${projectIDGlobal}&user_id=${user.userId}`)
            .then(response => response.json())
            .then(parsedData => {
                console.log('Workforce data:', parsedData);
                setHiddenWorkers(JSON.parse(parsedData.filtered_out_workers));
                const filteredWorkers = JSON.parse(parsedData.filtered_workers);
                console.log('Filtered workers:', filteredWorkers);
                filteredWorkers.forEach((row) => {
                    row.subcontractor = row.subcontractor_id ? findSubcontractorLabel(row.subcontractor_id) : '';
                });
                console.log('Filtered workers:', filteredWorkers);
                setRowData(filteredWorkers);
                setQualificationsWorkers(filteredWorkers.map(worker => ({
                    value: worker.id,
                    label: worker.name + ' | ' + worker.tag
                })));
                setLoading(false);
            });
    }, [subcontractors]);


    useEffect(() => {
        let url = `${apiUrl}/api/qualifications?project_id=${projectIDGlobal}&user_id=${user.userId}`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                setQualifications(parsedData);
            });
    }, [projectIDGlobal, triggerQualificationsRefresh]);

    useEffect(() => {
        fetch(`${apiUrl}/api/methods?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                setMethods(parsedData.map(method => ({
                    value: method.id,
                    label: method.name
                })));
            });
    }, [projectIDGlobal]);

    useEffect(() => {
        fetch(`${apiUrl}/api/materials`)
        .then(response => response.json())
        .then(data => {
            console.log('Materials:', data);
            const materialColumns = projectSettings.anyagminoseg_cols;
            console.log('MaterialColumns:', materialColumns);
            const materialData = uniqueMaterials(data, materialColumns);
            console.log('MaterialData:', materialData);
            setMaterials(materialData.list);
        });
    }, [projectSettings]);

    useEffect(() => {
        const subcontractorOptions = visibleSubcontractors.map(subcontractor => subcontractor.name);
        setSubcontractorOptions(subcontractorOptions);
    }, [visibleSubcontractors]);

    const findSubcontractorLabel = (subcontractorID) => {
        const subcontractor = subcontractors.find(subcontractor => subcontractor.id === subcontractorID);
        return subcontractor ? subcontractor.name : '';
    };

    const findSubcontractorId = (subcontractorLabel) => {
        const subcontractor = subcontractors.find(subcontractor => subcontractor.name === subcontractorLabel);
        if (!subcontractor) {
            throw new Error('Subcontractor not found');
        }
        return subcontractor.id;
    };

    const columnDefs = useMemo(() => [
        {
            headerName: 'Actions',
            maxWidth: 150,
            editable: false,
            cellRenderer: params => (
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <button onClick={() => editRow(params.data)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faEdit} style={{ fontSize: '1rem' }} />
                    </button>
                    <button onClick={() => deleteRow(params)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
                    </button>
                </div>
            )
        },
        {
            field: 'id',
            headerName: 'ID',
            editable: false,
            hide: true,
        },
        {
            field: 'name',
            headerName: 'Név',
            editable: false,
            cellRenderer: function (params) {
                if (!params.value) {
                    return <span style={{ color: 'red', opacity: 0.3 }}>Név</span>;
                }
                return params.value;
            }
        },
        {
            field: 'tag',
            headerName: 'Azonosító',
            editable: false,
            cellRenderer: function (params) {
                if (!params.value) {
                    return <span style={{ color: 'red', opacity: 0.3 }}>Azonosító</span>;
                }
                return params.value;
            }
        },
        {
            field: 'subcontractor',
            headerName: 'Alvállalkozó',
            editable: false,
            cellRenderer: function (params) {
                if (!params.value) {
                    return <span style={{ color: 'red', opacity: 0.3 }}>Alvállalkozó</span>;
                }
                return params.value;
            }
        },
        {
            headerName: 'Engedélyek',
            field: 'permissions', // New field for permissions
            maxWidth: 150,
            editable: false,
            cellRenderer: params => {
                const workerQualifications = qualifications.filter(qualification => qualification.worker_id === params.data.id);
                console.log('Worker qualifications:', workerQualifications);
                console.log('Worker:', params.data);
                console.log('Qualifications :', qualifications);
                const qualificationCount = workerQualifications ? workerQualifications.length : 0;
                
                return (
                    <Button
                        onClick={() => openPermissionModal(params.data)}
                        variant="outline"
                        size="xs"
                    >
                        {qualificationCount} Engedély
                    </Button>
                );
            }
        }
    ], [subcontractorOptions, qualificationsWorkers, qualifications]);
    

    const saveData = () => {
        const gridApi = gridRef.current.api;
        const rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        setLoading(true);
        let requestBody = rowData;

        requestBody = requestBody.map((row) => {
            const { saved, ...rest } = row;
            return rest;
        });

        requestBody = requestBody.map((row) => {
            if (row.subcontractor) {
                return { ...row, subcontractor_id: findSubcontractorId(row.subcontractor) };
            }
            return row;
        });

        fetch(`${apiUrl}/api/workforce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        }).then(response => response.json())
            .then(() => {
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const newRow = () => {
        const newRowData = {
            id: 0,
            name: '',
            tag: '',
            subcontractor: ''
        };
        setCurrentRowData(newRowData); // Set current row data to the new row
        setEditingTag(''); // Reset editing tag
        setIsModalOpen(true); // Open the modal
    };

    const deleteRow = (params) => {
        const gridApi = gridRef.current.api;
        gridApi.applyTransaction({ remove: [params.data] });
        // setRow data to current state of grid
        const rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        setRowData(rowData);
    };

    const editRow = (rowData) => {
        setCurrentRowData(rowData);
        setEditingTag(rowData.tag);
        setIsModalOpen(true);
    };

    const handleModalChange = (field, value) => {
        setCurrentRowData({ ...currentRowData, [field]: value });
    };

    const isTagUnique = (tag, editingTag) => {
        return !rowData.some(row => row.tag === tag) && !hiddenWorkers.some(worker => worker.tag === tag) || tag === editingTag;
    };

    const saveModalData = () => {
        if (editingTag === '') {
            setRowData([...rowData, currentRowData]);
        } else {
            const updatedRowData = rowData.map(row => {
                if (row.tag === editingTag) {
                    return currentRowData;
                }
                return row;
            });
            setRowData(updatedRowData);
        }

        setIsModalOpen(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openPermissionModal = (workerData) => {
        setSelectedWorker(workerData);
        setIsPermissionModalOpen(true);
    };

    const closePermissionModal = () => {
        setIsPermissionModalOpen(false);
        setSelectedWorker(null);
    };

    const isSaveDisabled = !currentRowData.name || !currentRowData.tag || !currentRowData.subcontractor || !isTagUnique(currentRowData.tag, editingTag);

    return (
        <>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                `}
            </style>

            <Button
                onClick={saveData}
                style={{ padding: '10px', marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
            >
                Mentés
            </Button>
            <Button
                onClick={newRow}
                style={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}
            >
                Új sor hozzáadása
            </Button>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        editType='modal'
                        gridOptions={{
                            singleClickEdit: true
                        }}
                        defaultColDef={{
                            flex: 1,
                            cellStyle: () => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }),
                        }}
                    />
                </LoadingWrapper>
            </div>

            <Modal
                opened={isModalOpen}
                onClose={closeModal}
                title="Edit Row"
            >
                <TextInput
                    label="Név"
                    value={currentRowData.name || ''}
                    onChange={(event) => handleModalChange('name', event.currentTarget.value)}
                    required
                />
                <TextInput
                    label="Azonosító"
                    value={currentRowData.tag || ''}
                    onChange={(event) => handleModalChange('tag', event.currentTarget.value)}
                    required
                    error={!isTagUnique(currentRowData.tag, editingTag) && 'Az azonosító már létezik'}
                />
                <Select
                    label="Alvállalkozó"
                    value={currentRowData.subcontractor || ''}
                    onChange={(value) => handleModalChange('subcontractor', value)}
                    data={subcontractorOptions}
                    required
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Button onClick={closeModal}>
                        Mégse
                    </Button>
                    <Button onClick={saveModalData} disabled={isSaveDisabled}>
                        Mentés
                    </Button>
                </div>
            </Modal>

            {/* Permission Modal */}
            <Modal
                opened={isPermissionModalOpen}
                onClose={closePermissionModal}
                title={selectedWorker ? `${selectedWorker.id} engedélyei` : 'Engedélyek'}
                size='auto'
            >
                <div
                    style={{
                        width: '88vw',
                    }}
                >
                    {selectedWorker ? (
                        <Qualifications
                            initialRowData={qualifications}
                            initialMethods={methods}
                            initialMaterials={materials}
                            initialWorkers={qualificationsWorkers}
                            workerId={selectedWorker.id}
                            saveQualificationsCallback={() => setTriggerQualificationsRefresh(!triggerQualificationsRefresh)}
                        />
                    ) : (
                        <div>Nincs kiválasztott hegesztő</div>
                    )}
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Button onClick={closePermissionModal}>
                        Bezárás
                    </Button>
                    {/* Add any other necessary buttons or functionality */}
                    </div>
            </Modal>

        </>
    );
};

export default Workforce;
