import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
import { FormGroup, Box, TextField } from '@mui/material';

const generateAndSmoothBrokenLine = (startDate, endDate, rampUpDays, rampDownDays, sigma1, sigma2) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const totalDays = end.diff(start, 'day');

  const x = Array.from({ length: totalDays + 1 }, (_, i) => start.add(i, 'day').toDate());
  const rampUpEnd = start.add(rampUpDays, 'day');
  const rampDownStart = end.subtract(rampDownDays, 'day');

  const y = new Array(x.length).fill(0);
  for (let i = 0; i < x.length; i++) {
    if (x[i] >= rampUpEnd.toDate() && x[i] < rampDownStart.toDate()) {
      y[i] = (x[i] - rampUpEnd.toDate()) / (rampDownStart.toDate() - rampUpEnd.toDate());
    } else if (x[i] >= rampDownStart.toDate()) {
      y[i] = 1;
    }
  }

  const sigma = new Array(x.length).fill(0);
  for (let i = 0; i < x.length; i++) {
    if (x[i] < rampUpEnd.toDate()) {
      sigma[i] = sigma1;
    } else if (x[i] >= rampUpEnd.toDate() && x[i] < rampDownStart.toDate()) {
      sigma[i] = sigma1 + (sigma2 - sigma1) * (x[i] - rampUpEnd.toDate()) / (rampDownStart.toDate() - rampUpEnd.toDate());
    } else {
      sigma[i] = sigma2;
    }
  }

  const applyGaussianSmoothing = (inputY) => {
    const smoothedY = new Array(inputY.length).fill(0);
    for (let i = 0; i < inputY.length; i++) {
      const localSigma = sigma[i];
      const startIdx = Math.max(0, i - Math.floor(localSigma * 3));
      const endIdx = Math.min(inputY.length, i + Math.floor(localSigma * 3) + 1);
      let sum = 0;
      let weights = 0;
      for (let j = startIdx; j < endIdx; j++) {
        const weight = Math.exp(-0.5 * Math.pow((j - i) / localSigma, 2));
        sum += inputY[j] * weight;
        weights += weight;
      }
      smoothedY[i] = sum / weights;
    }
    return smoothedY;
  };

  let smoothedY = applyGaussianSmoothing(y);
  smoothedY = applyGaussianSmoothing(smoothedY);  // Apply smoothing twice

  // Post-adjustment: Shift and scale
  const initialValue = smoothedY[0];
  for (let i = 0; i < smoothedY.length; i++) {
    smoothedY[i] -= initialValue;
  }
  const scale = 1 / smoothedY[smoothedY.length - 1];
  for (let i = 0; i < smoothedY.length; i++) {
    smoothedY[i] *= scale;
  }

  return x.map((date, index) => ({
    date,
    original: y[index],
    smoothed: smoothedY[index],
  }));
};

const calculateMaxDailyProgress = (data) => {
  let maxProgress = 0;
  for (let i = 1; i < data.length; i++) {
    const progress = data[i].smoothed - data[i - 1].smoothed;
    if (progress > maxProgress) {
      maxProgress = progress;
    }
  }
  return maxProgress;
};

const BrokenLineChart = () => {
  const [startDate, setStartDate] = useState(dayjs('2023-01-01'));
  const [endDate, setEndDate] = useState(dayjs('2023-12-31'));
  const [rampUpDays, setRampUpDays] = useState(30);
  const [rampDownDays, setRampDownDays] = useState(60);
  const [sigma1, setSigma1] = useState(3);
  const [sigma2, setSigma2] = useState(15);

  const data = generateAndSmoothBrokenLine(startDate, endDate, rampUpDays, rampDownDays, sigma1, sigma2);
  const maxDailyProgress = calculateMaxDailyProgress(data);

  return (
    <div>
      <div className="settings-ribon-container" style={{ padding: '10px', backgroundColor: '#f7f7f7', borderRadius: '8px', margin: '0px', textAlign: 'center' }}>
        <h3 style={{ fontSize: '1.5em', margin: '10px', color: '#333' }}>Lebontás:</h3>
        <FormGroup row style={{ justifyContent: 'space-around', flexWrap: 'wrap', gap: '20px' }}>
          <Box style={{ margin: '10px', textAlign: 'center' }}>
            <TextField
              label="Ramp Up Days"
              type="number"
              value={rampUpDays}
              onChange={(e) => setRampUpDays(+e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box style={{ margin: '10px', textAlign: 'center' }}>
            <TextField
              label="Ramp Down Days"
              type="number"
              value={rampDownDays}
              onChange={(e) => setRampDownDays(+e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box style={{ margin: '10px', textAlign: 'center' }}>
            <TextField
              label="Sigma1"
              type="number"
              value={sigma1}
              onChange={(e) => setSigma1(+e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box style={{ margin: '10px', textAlign: 'center' }}>
            <TextField
              label="Sigma2"
              type="number"
              value={sigma2}
              onChange={(e) => setSigma2(+e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Box>
        </FormGroup>
        <hr style={{ width: '100%' }} />
        <h3 style={{ fontSize: '1.5em', margin: '10px', color: '#333' }}>Elkészültség ekkor:</h3>
        <Box style={{ marginTop: '20px', textAlign: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
        </Box>
      </div>
      <div style={{ padding: '10px', textAlign: 'center', fontSize: '1.2em', color: '#333' }}>
        Max Daily Progress: {maxDailyProgress.toFixed(4)}
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={(date) => dayjs(date).format('YYYY-MM-DD')} />
          <YAxis />
          <Tooltip labelFormatter={(date) => dayjs(date).format('YYYY-MM-DD')} />
          <Legend />
          <Line type="monotone" dataKey="original" stroke="#8884d8" dot={false} strokeWidth={5} />
          <Line type="monotone" dataKey="smoothed" stroke="#82ca9d" dot={false} strokeWidth={5} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BrokenLineChart;
