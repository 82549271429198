import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, FormGroup, FormControlLabel, Box, IconButton, Collapse, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from 'react-select';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { AnalyticsContext } from './AnalyticsContext';

// Utility function to get unique values for a column
const getUniqueValues = (data, column) => {
    return [...new Set(data.map(item => item[column]))];
};

const SettingsRibbon = () => {
    const {
        groupBy,
        rawData,
        asOfDate,
        filters,
        setGroupBy,
        setFilteredData,
        setAsOfDate,
        setFilters
    } = useContext(AnalyticsContext);

    const [selectedColumns, setSelectedColumns] = useState(groupBy);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const columnDisplayNames = {
        alvallalkozo: 'Alvállalkozó',
        izometria_nev: 'Izometria',
        lap_szam: 'Lapszám',
        tipus: 'Típus',
        pozicio: 'Pozióció',
    };

    const groupByOptions = Object.keys(columnDisplayNames);

    useEffect(() => {
        setFilteredData(filters);
    }, [filters, setFilteredData]);

    const handleCheckboxChange = (event) => {
        const column = event.target.name;
        const isChecked = event.target.checked;

        setSelectedColumns((prevSelectedColumns) => {
            const updatedColumns = isChecked
                ? [...prevSelectedColumns, column]
                : prevSelectedColumns.filter(col => col !== column);

            setGroupBy(updatedColumns);
            return updatedColumns;
        });
    };

    const handleFilterChange = (column, selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFilters((prevFilters) => ({
            ...prevFilters,
            [column]: values,
        }));
    };

    const handleDateChange = (date) => {
        setAsOfDate(date);
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };


    return (
        <>
            <div 
                className='settings-ribbon-container' 
                style={{ padding: '10px', backgroundColor: '#f7f7f7', borderRadius: '8px', margin: '0px', textAlign: 'center', position: 'relative' }}
                onClick={isCollapsed ? toggleCollapse : undefined}
            >
                <IconButton
                    onClick={toggleCollapse}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                    size="small"
                >
                    {isCollapsed ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
                <Collapse in={!isCollapsed}>
                    <div>
                        <h3 style={{ fontSize: '1.5em', margin: '10px', color: '#333' }}>Lebontás:</h3>
                        <FormGroup row style={{ justifyContent: 'space-around', flexWrap: 'wrap', gap: '20px' }}>
                            {groupByOptions.map((option) => (
                                <div key={option} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '200px' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={option}
                                                checked={selectedColumns.includes(option)}
                                                onChange={handleCheckboxChange}
                                                color="primary"
                                            />
                                        }
                                        label={columnDisplayNames[option]}
                                    />
                                </div>
                            ))}
                        </FormGroup>
                        <hr style={{ width: '100%' }} />
                        <h3 style={{ fontSize: '1.5em', margin: '10px', color: '#333' }}>Szűrés:</h3>
                        <FormGroup row style={{ justifyContent: 'space-around', flexWrap: 'wrap', gap: '20px' }}>
                            {groupByOptions.map((option) => (
                                <div key={option} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '200px' }}>
                                    <Select
                                        isMulti
                                        options={getUniqueValues(rawData, option).map(value => ({ value, label: value.toString() }))}
                                        onChange={(selectedOptions) => handleFilterChange(option, selectedOptions)}
                                        placeholder={`${columnDisplayNames[option]} szerinti szűrés`}
                                        styles={{
                                            menu: (provided) => ({ ...provided, zIndex: 9999 }), // Ensure the dropdown is always on top
                                        }}
                                    />
                                </div>
                            ))}
                        </FormGroup>
                        <hr style={{ width: '100%' }} />
                        <h3 style={{ fontSize: '1.5em', margin: '10px', color: '#333' }}>Elkészültség ekkor:</h3>
                        <Box style={{ marginTop: '20px', textAlign: 'center' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Dátum"
                                    value={asOfDate}
                                    onChange={handleDateChange}
                                />
                            </LocalizationProvider>
                        </Box>
                    </div>
                </Collapse>
                {isCollapsed && (
                    <div style={{ textAlign: 'center', padding: '10px', fontSize: '1.2em', color: '#333' }}>
                        <h3> Lekérdezés beállítások </h3>
                    </div>
                )}
            </div>
        </>
    );
};

export default SettingsRibbon;
