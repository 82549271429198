function materialsMatch(data, material1, material2) {
    // Iterate over each row in the data
    for (const row of data) {
      // Check if both materials appear in the same row
      if (
        Object.values(row).includes(material1) &&
        Object.values(row).includes(material2)
      ) {
        return true; // They refer to the same thing
      }
    }
    return false; // They do not refer to the same thing
  }

  
export function filterWpsOptions(hegesztes, hegesztesMaterial, wpsList, materialsData) {
    return wpsList.filter(wps => {
        // Check if the material of the hegesztes matches the material of the WPS
        if (!materialsMatch(materialsData, wps.material, hegesztesMaterial)) {
            return false;
        }

        // Check if the hegesztes position is included in the WPS positions
        if (hegesztes.position && !wps.positions.includes(hegesztes.position)) {
            return false;
        }

        // Check if the diameter (D) of the hegesztes is within the WPS range
        if (hegesztes.D < wps.lower_d || hegesztes.D > wps.upper_d) {
            return false;
        }

        // Check if the wall thickness (v) of the hegesztes is within the WPS range
        if (hegesztes.v < wps.lower_v || hegesztes.v > wps.upper_v) {
            return false;
        }

        // If all checks pass, this WPS is applicable
        return true;
    });
}

export function filterWeldersOptions(hegesztes, hegesztesMaterial, wpsTable, welders, qualifications, materialsData) {
  console.log("Starting filterWeldersOptions function...");

  // Assign the material to hegesztes
  hegesztes.material = hegesztesMaterial;
  console.log("Assigned material to hegesztes:", hegesztes.material);

  // Join WPS info to hegesztes
  const wps = wpsTable.find(wps => wps.id === hegesztes.wps_id);
  if (wps) {
    hegesztes.method_ids = wps.method_ids;
    hegesztes.weld_material = wps.weld_material;
    console.log("WPS found:", wps);
    console.log("Assigned method_ids and weld_material to hegesztes:", hegesztes.method_ids, hegesztes.weld_material);
  } else {
    // If the WPS is not found, return an empty array
    console.log("WPS not found for the given hegesztes.wps_id:", hegesztes.wps_id);
    return welders;
  }

  // Filter for welders that have the required qualification for each of the methods
  const filteredWelders = welders.filter(welder => {
    console.log(`Checking welder ${welder.id}...`);

    const hasValidQualifications = hegesztes.method_ids.every(method_id => {
      console.log(`  Checking method_id ${method_id} for welder ${welder.id}...`);

      const welderQualifications = qualifications.filter(qualification => 
        qualification.welder_id === welder.id && 
        qualification.method_id === method_id &&
        qualification.weld_material === hegesztes.weld_material &&
        hegesztes.D >= qualification.lower_d && 
        hegesztes.D <= qualification.upper_d && 
        hegesztes.v >= qualification.lower_v && 
        hegesztes.v <= qualification.upper_v &&
        qualification.materials.some(material => {
          const match = materialsMatch(materialsData, material, hegesztes.material);
          console.log(`    Material match for welder ${welder.id}, qualification ${qualification.id}:`, match);
          return match;
        })
      );

      console.log(`  Found ${welderQualifications.length} valid qualifications for method_id ${method_id} and welder ${welder.id}.`);

      // If the welder has any valid qualifications for this method, they are qualified
      return welderQualifications.length > 0;
    });

    console.log(`Welder ${welder.id} is ${hasValidQualifications ? 'qualified' : 'not qualified'}.`);
    return hasValidQualifications;
  });

  console.log("Filtered welders:", filteredWelders.map(welder => welder.id));
  console.log("filterWeldersOptions function complete.");

  return filteredWelders;
}

