import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Select } from '@mantine/core';

const AgGridSingleSelectEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || null);
  const [initialOpen, setInitialOpen] = useState(true);
  const refInput = useRef(null);

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  useEffect(() => {
    console.log('value', value);
    console.log('initialOpen', initialOpen);
    setInitialOpen(false);

    if (value !== null && !initialOpen) {
      props.stopEditing();
    }
  }, [value]);

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    isPopup: () => true, // Ensures the dropdown is a popup extending beyond cell boundaries
  }));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className="ag-cell-edit-wrapper">
      <Select
        ref={refInput}
        className="ag-cell-editor"
        style={{ width: '100%', height: '100%' }}
        data={props.options}
        value={value}
        onChange={handleChange}
        searchable
        dropdownPosition="bottom"
        clearable
        withinPortal={true}
      />
    </div>
  );
});

export default AgGridSingleSelectEditor;
