import { useState, useEffect, useContext } from 'react';
import { Button, Modal, TextInput, Select } from '@mantine/core';
import { GlobalContext } from '../../Home/GlobalContext';
import uniqueMaterials from '../../../util/GetUniqueValues';

const AddSheetForm = ({
    sheets, 
    setSheets, 
    selectedRev, 
    setShowSheetModal 
  }) => {

    //get project id from global context
    const { projectSettings , subcontractors } = useContext(GlobalContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    //query materials
    const [materials, setMaterials] = useState([]);
    useEffect(() => {
      fetch(`${apiUrl}/api/materials`)
        .then(response => response.json())
        .then(data => {
          const columns = projectSettings.anyagminoseg_cols || [];
          const concatData = uniqueMaterials(data, columns);
          setMaterials(concatData.list);
        });
    }, []);

    // query subcontractors

    

    const [newSheetNumber, setNewSheetNumber] = useState('');
    const [newHeight, setNewHeight] = useState('');
    const [newMaterial, setNewMaterial] =  useState('');
    const [newSubcontractor, setNewSubcontractor] = useState('');
    const [newPosition, setNewPosition] = useState('');
  
    const handleAddSheet = () => {
 
    
        if (newSheetNumber, newHeight, newMaterial, newSubcontractor) {
          // get the id of the selected subcontractor
          const apiUrl = process.env.REACT_APP_API_URL;
          const selectedSubcontractor = subcontractors.find(subcontractor => subcontractor.name === newSubcontractor);

          fetch(`${apiUrl}/api/sheets?rev_id=${selectedRev.id}&sheet_number=${newSheetNumber}&height=${newHeight}&material=${newMaterial}&subcontractor=${selectedSubcontractor.id}&position=${newPosition}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => response.json())
          .then(data => {
            if (data.id) {
              setSheets([...sheets, { id: data.id, name: data.name }]);
              setShowSheetModal(false);
            } else {
              alert(data.error || "Failed to add project");
            }
          });
        }  else {
          alert('Hiányzó adatok');
        }
      };
  
    return (
      <div className='AddSheetFrom'>
        <TextInput
          label="Lap száma:"
          value={newSheetNumber}
          onChange={(event) => setNewSheetNumber(event.currentTarget.value)}
        />
        <TextInput
          label="Lap magassága:"
          value={newHeight}
          onChange={(event) => setNewHeight(event.currentTarget.value)}
        />
        <Select
          label="Lap anyaga:"
          data={materials}
          value={newMaterial}
          onChange={(value) => setNewMaterial(value)}
          searchable={true}
          dropdownPosition='bottom'
        />
        <Select
          label="Lap alvállakozó:"
          data={subcontractors.map(subcontractor => subcontractor.name)}
          value={newSubcontractor}
          onChange={(value) => setNewSubcontractor(value)}
          searchable={true}
          dropdownPosition='bottom'
        />
        <TextInput
          label="Lap pozíció:"
          value={newPosition}
          onChange={(event) => setNewPosition(event.currentTarget.value)}
        />
        <Button className='mt-1' onClick={handleAddSheet}>Mentés</Button>
      </div>
    );
  };
  
  export default AddSheetForm;