import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Text, Container, Space, Title, Center, Group } from '@mantine/core';
import MobileTracking from './MobileTracking';

const MobilePWAPrompt = () => {
  const navigate = useNavigate();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(true);
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(isPWA);

    if (isPWA) {
      navigate('/mobile/project-selection');
    }

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    if (window.deferredPrompt) {
      setDeferredPrompt(window.deferredPrompt);
      setIsInstallable(true);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [navigate]);

  const handleBackToApp = () => {
    navigate('/project-selection');
  };

  const handleInstallPWA = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('A felhasználó elfogadta a telepítési ajánlatot');
      } else {
        console.log('A felhasználó elutasította a telepítési ajánlatot');
      }
      setDeferredPrompt(null);
    } else {
      alert('A PWA telepítése nem támogatott vagy nem érhető el jelenleg.');
    }
  };

  if (true) {
    navigate('/mobile/project-selection');
  }

  return (
    <Container style={{ textAlign: 'center', padding: '20px' }}>
      <Title order={2}>Átirányítás a mobil verzióra</Title>
      <Space h="md" />
      <Text size="sm">
        Jelenleg a webalkalmazás mobil verzióját nézi. Innen visszatérhet a teljes alkalmazáshoz,
        vagy telepítheti a mobil applikációt jobb felhasználói élmény érdekében.
      </Text>
      <Text size="sm">
            Jelenleg csak a nyomon követési adatok bevitele érhető el a mobil verzióban.
        </Text> 
      <Space h="lg" />
      <Center>
        <Group>
          <Button onClick={handleBackToApp} variant="outline" size="md">
            Vissza a teljes webalkalmazásra
          </Button>

          {isInstallable ? (
            <Button onClick={handleInstallPWA} size="md">
                Mobil applikáció telepítése
            </Button>
          ) : (
            <Text size="sm">A mobil applikáció telepítés nem érhető el ezen a böngészőn/eszközön.</Text>
          )}
        </Group>
      </Center>
    </Container>
  );
};

export default MobilePWAPrompt;
