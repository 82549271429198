import { useState, useContext } from 'react';
import { Button, Modal, TextInput, Select } from '@mantine/core';
import { GlobalContext } from '../../Home/GlobalContext';

const AddIzometriaForm = ({ izometrias, setIzometrias, setShowIzometriaModal }) => {
  const [newIzometriaName, setNewIzometriaName] = useState('');

  const { projectIDGlobal } = useContext(GlobalContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleAddIzometria = () => {
    if (newIzometriaName) {
        fetch(`${apiUrl}/api/izometrias?project_id=${projectIDGlobal}&izometria_name=${newIzometriaName}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data.id) {
          setIzometrias([...izometrias, { id: data.id, name: data.name }]);
          setShowIzometriaModal(false);
        } else {
          alert(data.error || "Failed to add project");
        }
      });
    }
  };


  return (
    <div>
      <TextInput
        label="Uj izometria jele:"
        value={newIzometriaName}
        onChange={(event) => setNewIzometriaName(event.currentTarget.value)}
      />
      <Button className='mt-1' onClick={handleAddIzometria}>Add Izometria</Button>
    </div>
  );
};

export default AddIzometriaForm;