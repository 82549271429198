import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './Home/AuthContext';
import Cards from './Atoms/Cards';
import { GlobalContext } from './Home/GlobalContext';

const ProjectSelector = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const {
    setProjectNameGlobal,
    setProjectIDGlobal,
    permissionsGlobal,
    setProjectSettings
  } = useContext(GlobalContext);
  const apiUrl = process.env.REACT_APP_API_URL;


  useEffect(() => {
    fetch(`${apiUrl}/api/projects?user_id=${user.userId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.json())
    .then(data => setProjects(data));
  }, [apiUrl, user.userId]);

  useEffect(() => {
    if (selectedProject && permissionsGlobal !== undefined) {
      const currentPath = window.location.pathname;

      if (currentPath === '/project-selection') {
        navigate(`/project/${selectedProject}/analytics`);
      } else if (currentPath === '/mobile/project-selection') {
        navigate(`/mobile/project/${selectedProject}/tracking`);
      } else {
        new Error('Invalid path');
      }
    }
  }, [permissionsGlobal, selectedProject, navigate]);

  const handleProjectSelect = (project_id) => {
    const project = projects.find(p => p.project_id === project_id);
    if (project) {
      setProjectNameGlobal(project.project_name);
      setProjectIDGlobal(project.project_id);
      setProjectSettings({ anyagminoseg_cols: project.anyagminoseg_cols, require_done_by: project.require_done_by });
      setSelectedProject(project_id);
    }
  };

  const handleAddProject = () => {
    const projectName = prompt("Enter the new project name:");
    if (projectName) {
      fetch(`${apiUrl}/api/projects?user_id=${user.userId}&project_name=${projectName}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: projectName })
      })
      .then(response => response.json())
      .then(data => {
        if (data.project_id) {
          setProjects([...projects, { project_id: data.project_id, project_name: data.project_name }]);
          alert("Project added successfully!");
        } else {
          alert(data.error || "Failed to add project");
        }
      });
    }
  };

  return (
    <div>
      <Cards
        initialItems={projects.map(project => ({ id: project.project_id, name: project.project_name }))}
        title="Válassz projektet"
        onSelect={handleProjectSelect}
        // onAdd={handleAddProject}
      />
    </div>
  );
}

export default ProjectSelector;
