import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Home/AuthContext';
import jwtDecode from 'jwt-decode';

const Login = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');  

    const { login } = useAuth();
    const navigate = useNavigate();

    // Handle form submission for login
    const onSubmitForm = async (e) => {
        e.preventDefault();
        try {
            const body = { username, password };
            const response = await fetch(`${apiUrl}/api/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            });

            const responseData = await response.json();

            if (response.status === 200) {
                const jwtToken = responseData.access_token;
                const refreshToken = responseData.refresh_token;
                const decodedToken = jwtDecode(jwtToken);

                const userData = {
                    username: decodedToken.sub,
                    userId: decodedToken.userId
                };

                // Log in the user and navigate to home
                login(userData, jwtToken, refreshToken);
                navigate('/');
            } else {
                setMessage("Invalid credentials!");
            }
        } catch (err) {
            console.error(err.message);
            setMessage("Error occurred during login.");
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <h1 className="text-center my-5">Login</h1>
                    {message && <div className="alert alert-info">{message}</div>}
                    <form onSubmit={onSubmitForm}>
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                id="username"
                                placeholder="Username"
                                className="form-control"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="form-group" style={{ marginBottom: '20px' }}>
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Password"
                                className="form-control"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                        <button className="btn btn-primary">Login</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
