import React, { useEffect, useContext, useState } from 'react';
import { GlobalContext } from '../../Home/GlobalContext';
import AuthContext from '../../Home/AuthContext';
import { Loader } from '@mantine/core';
import LoadingWrapper from '../../Atoms/Loading';
import SettingsRibbon from './SettingsRibbon';
import PivotTable from './PivotTable';
import ChartView from './ChartView';  // Import the new ChartView component
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Button } from '@mui/material';
import { AnalyticsContext } from './AnalyticsContext';

dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);


const Analytics = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { projectIDGlobal, permissionsGlobal } = useContext(GlobalContext);
    const { user } = useContext(AuthContext);

    const [viewMode, setViewMode] = useState('table');

    const {
        groupBy,
        rawData,
        filteredData,
        pivotData,
        loading,
        asOfDate,
        filters,
        setRawData,
        setFilteredData,
        setPivotData,
        setLoading
    } = useContext(AnalyticsContext);

    useEffect(() => {
        if (!user) return;
        if (!user.userId) return;
        console.log(user);
        setLoading(true);
        fetch(`${apiUrl}/api/pivot_data?project_id=${projectIDGlobal}&user_id=${user.userId}`)
            .then(response => response.json())
            .then(data => {
                const filledData = data.map(item => ({
                    ...item,
                    munkaora: item.munkaora || 0, // Fill null or undefined 'munkaora' with 0
                    kesz: item.kesz !== undefined ? item.kesz : false, // Ensure 'kesz' is always a boolean
                    date: item.datum ? dayjs(item.datum).toDate() : null // Convert 'datum' to a Date object
                }));
                setRawData(filledData);
                setFilteredData(filledData);
            })
            .finally(() => setLoading(false));
    }, [apiUrl, projectIDGlobal, user, setLoading, setRawData, setFilteredData]);

    useEffect(() => {
        if (groupBy.length > 0) {
            setPivotData(calculatePivotData(filteredData, groupBy));
        } else {
            // Summing munkaora without any grouping
            const totalMunkaora = filteredData.reduce((sum, item) => sum + (item.munkaora || 0), 0);
            const totalKeszMunkaora = filteredData.reduce((sum, item) => {
                const itemDate = item.datum ? dayjs(item.datum) : null;
                if (item.kesz && itemDate && itemDate.isSameOrBefore(asOfDate.add(1, 'day'), 'day')) {
                    return sum + (item.munkaora || 0);
                }
                return sum;
            }, 0);
            const keszPercentage = totalMunkaora > 0 ? (totalKeszMunkaora / totalMunkaora) * 100 : 0;

            setPivotData([{
                totalMunkaora: Number(totalMunkaora.toFixed(2)),
                totalKeszMunkaora: Number(totalKeszMunkaora.toFixed(2)),
                keszPercentage: Number(keszPercentage.toFixed(2))
            }]);
        }
    }, [groupBy, filteredData, asOfDate, setPivotData]);

    useEffect(() => {
        let filtered = rawData;
        Object.keys(filters).forEach((column) => {
            const values = filters[column];
            if (values.length > 0) {
                filtered = filtered.filter(item => values.includes(item[column]));
            }
        });
        setFilteredData(filtered);
    }, [filters, rawData]);

    const calculatePivotData = (data, groupByColumns) => {
        const groupedData = data.reduce((acc, curr) => {
            // Create a composite key based on the groupBy columns
            const key = groupByColumns.map(col => curr[col]).join('_');

            if (!acc[key]) {
                acc[key] = {
                    ...groupByColumns.reduce((obj, col) => ({ ...obj, [col]: curr[col] }), {}),
                    totalMunkaora: 0,
                    totalKeszMunkaora: 0,
                    keszPercentage: 0 // Initialize keszPercentage
                };
            }

            // Add to the total munkaora
            acc[key].totalMunkaora += curr.munkaora || 0; // Ensure munkaora is a number

            // Convert datum to a dayjs object if it's not already one and compare dates
            const currDatum = curr.datum ? dayjs(curr.datum) : null;
            if (curr.kesz && currDatum && currDatum.isSameOrBefore(asOfDate.add(1, 'day'), 'day')) {
                acc[key].totalKeszMunkaora += curr.munkaora || 0; // Ensure munkaora is a number
            }

            // Calculate the percentage of kesz munkaora
            if (acc[key].totalMunkaora > 0) {
                acc[key].keszPercentage = (acc[key].totalKeszMunkaora / acc[key].totalMunkaora) * 100;
            }

            return acc;
        }, {});

        return Object.values(groupedData);
    };


    const columnDisplayNames = {
        alvallalkozo: 'Alvállalkozó',
        izometria_nev: 'Izometria',
        lap_szam: 'Lapszám',
        tipus: 'Típus',
        pozicio: 'Pozióció',
    };

    return (
        <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
            <div className='analytics-container' style={{ padding: '5px', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <SettingsRibbon/>
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <Button variant="contained" color="primary" onClick={() => setViewMode('table')}>
                        Táblázat
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => { setViewMode('chart') }} style={{ marginLeft: '10px' }}>
                        Grafikon
                    </Button>
                </div>
                <div className='data-display-container' style={{ marginTop: '20px', flexGrow: 1 }}>
                    {viewMode === 'table' ? (
                        <>
                            <PivotTable pivotData={pivotData} groupBy={groupBy} asOfDate={asOfDate} columnDisplayNames={columnDisplayNames} />
                        </>
                    ) : (
                        <>
                            <ChartView/>
                        </>
                    )}
                </div>
            </div>
        </LoadingWrapper>
    );
};

export default Analytics;
