import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import jwtDecode from 'jwt-decode';  // Import the jwt-decode library
import { useContext } from 'react';
import { GlobalContext } from './GlobalContext';
import LoadingWrapper from '../Atoms/Loading';
import { Loader } from '@mantine/core';

const ProtectedRoute = ({ children, requiredAccess, redirectPath }) => {
    const { loading, isAuthenticated, token, user } = useAuth();
    const { permissionsGlobal } = useContext(GlobalContext);
 
    // Check if the token has expired
    const isTokenExpired = () => {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp < currentTime;
        } catch (err) {
            return true;  // If there's an error decoding the token, consider it expired
        }
    };

    // Check if the user has the required access
    const hasRequiredAccess = () => {
        if (!permissionsGlobal) {
            return(
            <LoadingWrapper isLoading={true} loadingComponent={Loader}>
                <div style={{height:'100%', width:'100%'}}/>
            </LoadingWrapper>)
        }
        console.log('Checking user permissions:', permissionsGlobal);
        return permissionsGlobal[requiredAccess];
    };

    // If the user is not authenticated, the token has expired, or the user lacks the required access, redirect accordingly
    if (loading) {
        return null;  // Render a placeholder while we're loading
    }
    
    if (!isAuthenticated || isTokenExpired()) {
        return <Navigate to="/login" replace />;
    }


    if (!requiredAccess) {
        return children;
    }

    if (requiredAccess && !hasRequiredAccess()) {
        console.log('User lacks required access:', requiredAccess);
        const path = redirectPath || '/';
        return <Navigate to={path} replace />;
    }

    return children;
};

export default ProtectedRoute;
