import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Button,
  Select,
  Box,
  Title,
  Text,
  Paper,
  Group,
} from '@mantine/core';
import axios from 'axios';
import { GlobalContext } from '../../Home/GlobalContext';

const ProjectSettings = () => {
  const { projectSettings, setProjectSettings, projectIDGlobal } = useContext(GlobalContext);
  const [requireDoneBy, setRequireDoneBy] = useState(projectSettings.require_completed_by || false);
  const [materialColumns, setMaterialColumns] = useState(projectSettings.anyagminoseg_cols || []);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setMaterialColumns(projectSettings.anyagminoseg_cols || []);
    setRequireDoneBy(projectSettings.require_done_by || false);
  }, [projectSettings]);

  useEffect(() => {
    console.log('requireDoneBy:', requireDoneBy);
  }, [requireDoneBy]);

  useEffect(() => {
    console.log('materialColumns:', materialColumns);
  }, [materialColumns]);

  const handleRequireDoneByChange = (event) => {
    setRequireDoneBy(event.currentTarget.checked);
  };

  const handleMaterialColumnsChange = (value) => {
    setMaterialColumns(value);
  };

  const handleSave = async () => {
    const updatedSettings = {
      project_id: projectIDGlobal,
      anyagminoseg_cols: materialColumns,
      require_completed_by: requireDoneBy
    };

    fetch(`${apiUrl}/api/update_project_settings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedSettings)
    });

    setProjectSettings({ anyagminoseg_cols: materialColumns, require_completed_by: requireDoneBy });
  };

  return (
    <Box component={Paper} padding="md" style={{ display: 'flex', flexDirection: 'column' }}>
      <Title order={6}>Projekt beállítások</Title>
      <Group direction="column" spacing="sm" mt="md">
        <Checkbox
          label="Kötelezően megadandó hegesztő"
          checked={requireDoneBy}
          onChange={handleRequireDoneByChange}
        />
      </Group>
      <Select
        label="Anyagminőség tipusok"
        placeholder="Válassz ki tipusokat"
        data={['mm', 'din', 'en', 'mt', 'astm'].map((column) => ({ value: column, label: column.toUpperCase() }))}
        value={materialColumns}
        onChange={handleMaterialColumnsChange}
        searchable
        clearable
        multiple
        mt="md"
      />
      <Button variant="filled" color="blue" onClick={handleSave} style={{ width: '70px', marginTop: '10px' }}>
        Mentés
      </Button>
    </Box>
  );
};

export default ProjectSettings;
