import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from '@mantine/core';

// CSS styles
const styles = {
  loadingContainer: {
    position: 'relative',
    flexGrow: 1
  },
  loaderOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.7)',  // Adjust the background color and opacity as needed
    zIndex: 10
  },
  blurredContent: {
    filter: 'blur(2px)'  // Adjust the blur value as needed
  }
};

const LoadingWrapper = ({ children, isLoading, loadingComponent=Loader }) => {
  const location = useLocation();
  const [contentKey, setContentKey] = useState(location.pathname);

  useEffect(() => {
    setContentKey(location.pathname);
  }, [location.pathname]);

  return (
    <div
      className='loading-container'
      style={{
        position: 'relative',
        height: '100%',
        width: '100%'
      }}
    >
      {isLoading && (
        <div
          className='loader-overlay'
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, 0.7)',  // Adjust the background color and opacity as needed
            zIndex: 10
          }}
        >
          {loadingComponent}
        </div>
      )}
      
      <div className="loading-blur" key={contentKey} style={isLoading ? { filter: 'blur(2px)', height: '100%' } : { height: '100%' }}>
        {children}
      </div>
    </div>
  );
};

export default LoadingWrapper;
