import React, { useState, useEffect, useContext } from 'react';
import { Card, Badge, Modal, Grid, Text, Checkbox, Button } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import LoadingWrapper from '../Atoms/Loading';
import { Loader } from '@mantine/core';
import * as moment from 'moment';
import AuthContext from '../Home/AuthContext';

const KarimaszerelesTrackingMobile = ({ selectedSheet }) => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const { user } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);
        fetch(`${apiUrl}/api/get_karimaszereles_tracking?sheet_id=${selectedSheet.id}`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                parsedData.sort((a, b) => a.sorszam - b.sorszam);
                parsedData.forEach((row) => {
                    row.date = row.date ? moment(row.date).toDate() : null;
                    row.is_done = !!row.progress_id;
                    row.modified = false;
                });
                setRowData(parsedData);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [selectedSheet.id]);

    const saveData = (updatedData = rowData) => {
        setLoading(true);
        const requestBody = {
            data: updatedData,
            user_id: user.userId
        };

        fetch(`${apiUrl}/api/save_karimaszereles_tracking`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleCardClick = (row) => {
        if (row.is_done && !row.date) {
            row.date = moment().toDate();
        }
        setSelectedRow(row);
        setOpened(true);
    };

    const handleModalSave = () => {
        const updatedData = rowData.map((row) => {
            if (row.sorszam === selectedRow.sorszam) {
                return selectedRow;
            }
            return row;
        });

        setRowData(updatedData);
        setOpened(false);
        saveData(updatedData);
    };

    const handleFieldChange = (field, value) => {
        setSelectedRow({ ...selectedRow, [field]: value });
    };

    const renderCard = (row) => (
        <div key={row.sorszam} onClick={() => handleCardClick(row)} style={{ cursor: 'pointer' }}>
            <Card shadow="sm" padding="lg" style={{ marginBottom: '10px' }}>
                <Grid>
                    <Grid.Col span={8}>
                        {/* "Sorszám" as dominant label and "Típus" below it */}
                        <Text weight={600} size="lg">{row.sorszam}</Text>
                        <Text size="sm" color="dimmed">{row.tipus}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        {/* Displaying the "Done" status badge and "Munkaóra" below it */}
                        <Badge color={row.is_done ? 'green' : 'red'}>{row.is_done ? 'Elkészült' : 'Folyamatban'}</Badge>
                        <Text size="sm" style={{ marginTop: '5px' }}>Munkaóra: {row.munkaora}</Text>
                    </Grid.Col>
                </Grid>
            </Card>
        </div>
    );

    return (
        <>
            <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                <div>
                    {rowData.map(renderCard)}
                </div>
            </LoadingWrapper>

            <Modal opened={opened} onClose={() => setOpened(false)} title="Részletek">
                {selectedRow && (
                    <div>
                        <Text>Sorszám: {selectedRow.sorszam}</Text>
                        <Text>Típus: {selectedRow.tipus}</Text>
                        <Text>D: {selectedRow.d}</Text>

                        <Checkbox
                            label="Elkészült"
                            checked={selectedRow.is_done}
                            onChange={(event) => handleFieldChange('is_done', event.currentTarget.checked)}
                            style={{ marginTop: '10px' }}
                        />

                        {selectedRow.is_done && (
                            <>
                                <DatePicker
                                    label="Elkészülés dátuma"
                                    placeholder="Válasszon dátumot"
                                    value={selectedRow.date || moment().toDate()}
                                    onChange={(value) => handleFieldChange('date', value)}
                                    style={{ marginTop: '10px' }}
                                />
                            </>
                        )}
                        <Button fullWidth style={{ marginTop: '20px' }} onClick={handleModalSave}>
                            Mentés
                        </Button>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default KarimaszerelesTrackingMobile;
