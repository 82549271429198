import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import  customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import { Button } from '@mantine/core';
import { ContactlessOutlined } from '@mui/icons-material';
import moment from 'moment';
import { AnalyticsContext } from './AnalyticsContext';

dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

const ChartView = () => {

    const { filteredData, groupBy } = useContext(AnalyticsContext);

    const [chartData, setChartData] = useState([]);
    const [groupKeys, setGroupKeys] = useState([]);
    const [tooManyCategories, setTooManyCategories] = useState(false);
    const [showCompletionPercentage, setShowCompletionPercentage] = useState(true);
    const [chartMinDate, setChartMinDate] = useState(null);
    const [maxMunkaora, setMaxMunkaora] = useState(0);
    const chartRef = useRef(null);

    // Memoize the date range to avoid recalculating on every render
    const dateRange = useMemo(() => {
        console.log('filteredData', filteredData);
        let minDate;
        let maxDate;
        
        if (filteredData.length > 0) {
            const dateObjects = filteredData.map(item => dayjs(item.datum));
            const validDateObjects = dateObjects.filter(date => date.isValid());
            console.log('valid', validDateObjects);
            console.log('min', dayjs.min(dateObjects));
            minDate = dayjs.min(validDateObjects).startOf('day').toDate();
            maxDate = dayjs.max(validDateObjects).startOf('day').toDate();
        } else {
            minDate = dayjs().startOf('day').toDate();
            maxDate = dayjs().startOf('day').toDate();
        }

        const getDateRange = (startDate, endDate, steps) => {
            const stepSize = (endDate - startDate) / steps;
            const dates = [];
            for (let i = 0; i <= steps; i++) {
                dates.push(dayjs(startDate).add(stepSize * i, 'millisecond').startOf('day').toDate());
            }
            return dates;
        }

        console.log('getDateRange', getDateRange(minDate, maxDate, 50));

        return getDateRange(minDate, maxDate, 50);
    }, [filteredData]);

    useEffect(() => {
        const groupData = {};
        let totalMaxMunkaora = 0;

        filteredData.forEach(item => {
            const groupKey = groupBy.length > 0 ? groupBy.map(key => item[key]).join(' | ') : 'Teljes';
            if (!groupData[groupKey]) {
                groupData[groupKey] = { totalMunkaora: 0, completedMunkaora: {} };
            }
            groupData[groupKey].totalMunkaora += item.munkaora;
            totalMaxMunkaora = Math.max(totalMaxMunkaora, groupData[groupKey].totalMunkaora);
            const completionDate = dayjs(item.datum).startOf('day').toDate();
            if (completionDate.toString() !== 'Invalid Date') {
                if (!groupData[groupKey].completedMunkaora[completionDate]) {
                    groupData[groupKey].completedMunkaora[completionDate] = 0;
                }
                groupData[groupKey].completedMunkaora[completionDate] += item.munkaora;
            }
        });

        const newGroupKeys = Object.keys(groupData);
        setGroupKeys(newGroupKeys);

        if (newGroupKeys.length > 30) {
            setTooManyCategories(true);
            return;
        } else {
            setTooManyCategories(false);
        }

        setMaxMunkaora(totalMaxMunkaora);

        if (!dateRange) {
            return;
        }
    
        const seriesData = dateRange.map(date => {
            const seriesItem = { date: date };
            newGroupKeys.forEach(groupKey => {
                const group = groupData[groupKey];
                let sum = 0;
                Object.entries(group.completedMunkaora).forEach(([completionDate, munkaora]) => {
                    if (dayjs(completionDate).isSameOrBefore(date, 'day')) {
                        sum += munkaora;
                    }
                });
                if (showCompletionPercentage) {
                    seriesItem[groupKey] = group.totalMunkaora !== 0 ? (sum / group.totalMunkaora) * 100 : 0;
                } else {
                    seriesItem[groupKey] = sum;
                }
            });
            return seriesItem;
        });

        const combinedData = seriesData;

        const minCombinedDate = dayjs(Math.min(...combinedData.map(item => dayjs(item.date).valueOf())));
        combinedData.forEach(item => {
            item.date = dayjs(item.date).diff(minCombinedDate, 'day');
        });

        console.log('combinedData', combinedData);

        setChartMinDate(minCombinedDate);
        setChartData(combinedData);
    }, [filteredData, groupBy, dateRange, showCompletionPercentage]);

    // Generate vibrant colors
    const generateVibrantColor = () => {
        const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
        const r = getRandomInt(0, 200);
        const g = getRandomInt(0, 200);
        const b = getRandomInt(0, 200);
        return `rgb(${r},${g},${b})`;
    };

    const handleChange = (event, newValue) => {
        setShowCompletionPercentage(newValue === 'completion');
    };

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(chartData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ChartData');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'chart_data.xlsx');
    };

    const downloadPng = async () => {
        const canvas = await html2canvas(chartRef.current);
        canvas.toBlob((blob) => {
            saveAs(blob, 'chart.png');
        });
    };
    if (!chartData || chartData.length === 0) {
        return <div>Loading...</div>;
    }
    return (
        <div className="chart-container" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className="graph-view-switcher" style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                <ToggleButtonGroup
                    value={showCompletionPercentage ? 'completion' : 'munkaora'}
                    exclusive
                    onChange={handleChange}
                    aria-label="view toggle"
                >
                    <ToggleButton value="completion" aria-label="show completion">
                        Százalék
                    </ToggleButton>
                    <ToggleButton value="munkaora" aria-label="show total munkaora">
                        Munkaóra
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div className="chart-inner-container" style={{ width: '100%', flexGrow: 1 }}>
                {tooManyCategories ? (
                    <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1,
                        backdropFilter: 'blur(5px)'
                    }}>
                        <p style={{ fontSize: '20px', color: 'red' }}>Túl sok kategória. Távolítson el csoportosítási kategóriákat, vagy állítson be szűrőket.</p>
                    </div>
                ) : (
                    <ResponsiveContainer width="100%" height="100%" ref={chartRef}>
                        <LineChart
                            data={chartData}
                            margin={{
                                top: 5, right: 30, left: 30, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                type='number'
                                tickFormatter={(date) => chartMinDate.add(date, 'day').format('YYYY-MM-DD')}
                            />
                            <YAxis
                                domain={[0, showCompletionPercentage ? 100 : undefined]}
                                label={{ value: showCompletionPercentage ? 'Elkészültség (%)' : 'Munkaóra', angle: -90, position: 'insideLeft' }}
                                tickFormatter={showCompletionPercentage ? (value) => `${value}%` : (value) => Math.round(value).toLocaleString('hu-HU')}
                            />
                            <Tooltip />
                            <Legend />
                            {groupKeys.map(groupKey => (
                                <Line
                                    key={groupKey}
                                    type="monotone"
                                    dataKey={groupKey}
                                    stroke={generateVibrantColor()} // vibrant color for each line
                                    dot={false}
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', margin: '5px', padding: '5px' }}>
                <Button size="xs" color="blue" variant="outline" onClick={downloadExcel} style={{ marginRight: '10px' }}>
                    Excel letöltés
                </Button>
                <Button size="xs" color="blue" variant="outline" onClick={downloadPng}>
                    PNG letöltés
                </Button>
            </div>
        </div>
    );
};

export default ChartView;
