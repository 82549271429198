import React, { useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as XLSX from 'xlsx';
import { Button } from '@mantine/core';

const PivotTable = ({ pivotData, groupBy, asOfDate, columnDisplayNames }) => {
    const gridRef = useRef();

    const columnDefs = [

        ...groupBy.map(column => ({ headerName: columnDisplayNames[column], field: column })),
        
        {
            headerName: 'Összes munkaóra',
            field: 'totalMunkaora',
            valueFormatter: (params) => {
                const value = params.value;
                return typeof value === 'number' ? value.toLocaleString('hu-HU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00';
            }
        },
        {
            headerName: 'Kész munkaórák',
            field: 'totalKeszMunkaora',
            valueFormatter: (params) => {
                const value = params.value;
                return typeof value === 'number' ? value.toLocaleString('hu-HU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00';
            }
        },
        {
            headerName: 'Elkészültég (%)',
            field: 'keszPercentage',
            valueFormatter: (params) => {
                const value = params.value;
                return typeof value === 'number' ? value.toLocaleString('hu-HU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0.00%';
            }
        }
    ];

    const handleExport = () => {
        const rowData = [];
        gridRef.current.api.forEachNode(node => rowData.push(node.data));

        const worksheet = XLSX.utils.json_to_sheet(rowData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Pivot Data');

        const formattedDate = new Date(asOfDate).toISOString().slice(0, 10).replace(/-/g, '');
        const fileName = `pivot_data_${groupBy.join('_')}_${formattedDate}.xlsx`;
        XLSX.writeFile(workbook, fileName);
    };

    return (
        <div className="pivot-table-container" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className="ag-theme-alpine" style={{ width: '100%', flexGrow: 1 }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={pivotData}
                    columnDefs={columnDefs}
                    defaultColDef={{ flex: 1, sortable: true, filter: true, resizable: true }}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', width: '100%', margin: '5px', padding: '5px' }}>
                <Button size="xs" color="blue" variant="outline" onClick={handleExport}>Excel letöltés</Button>
            </div>
        </div>
    );
};

export default PivotTable;
