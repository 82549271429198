function uniqueMaterials(data, columns) {
    const uniqueValues = {};
  
    // Initialize an empty set for each column to store unique values
    columns.forEach(column => {
        uniqueValues[column] = new Set();
    });
  
    // Iterate over each row in the data array
    data.forEach(row => {
        // For each specified column, add the value to the respective set
        columns.forEach(column => {
            if (row[column]) {
                uniqueValues[column].add(row[column]);
            }
        });
    });
  
    // Convert sets to arrays
    const result = {};
    for (const column in uniqueValues) {
        result[column] = Array.from(uniqueValues[column]);
    }
  
    //Format it into single array
    let concatData = [];
    for (const column in result) {
        concatData = concatData.concat(result[column]);
    }

    console.log('Returning:', {dict: result, list: concatData});
  
    return {dict: result, list: concatData};
  }
  
export default uniqueMaterials;