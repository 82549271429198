import React, { useState, useEffect, useContext } from 'react';
import { Stepper, Group, Button, Loader, Modal } from '@mantine/core';
import { AuthContext } from '../../Home/AuthContext';
import { GlobalContext } from '../../Home/GlobalContext';
import Cards from '../../Atoms/Cards';
import LoadingWrapper from '../../Atoms/Loading';
import TrackingTabs from './TrackingTabs';

const Tracking = () => {
  const { user } = useContext(AuthContext);
  const { projectNameGlobal, projectIDGlobal } = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [izometrias, setIzometrias] = useState([]);
  const [selectedIzometria, setSelectedIzometria] = useState(null);
  const [revs, setRevs] = useState([]);
  const [selectedRev, setSelectedRev] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Log selectedIzometria, selectedRev, selectedSheet
  useEffect(() => {
    console.log("selectedIzometria: ", selectedIzometria);
    console.log("selectedRev: ", selectedRev);
    console.log("selectedSheet: ", selectedSheet);
  }, [selectedIzometria, selectedRev, selectedSheet]);

  useEffect(() => {
    console.log(sheets);
  }, [sheets]);

  useEffect(() => {
    if (selectedIzometria) {
      console.log("revs: ", revs);
      const selectedRev = revs.find(item => item.izometria_id === selectedIzometria.id);
      setSelectedRev(selectedRev);
    }
  }, [selectedIzometria]);

  useEffect(() => {
    // Fetch Izometrias
    fetch(`${apiUrl}/api/tracking_izometrias?project_id=${projectIDGlobal}&user_id=${user.userId}`)
      .then(response => response.json())
      .then(data => {
        console.log("fetched revs: ", data.revs);
        setIzometrias(data.izometrias);
        setRevs(data.revs);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedRev) {
      // Fetch Sheets for the selected Revision
      fetch(`${apiUrl}/api/tracking_sheets?rev_id=${selectedRev.id}&project_id=${projectIDGlobal}&user_id=${user.userId}`)
        .then(response => response.json())
        .then(data => {
          setSheets(data);
          setLoading(false);
        });
    }
  }, [selectedRev]);

  const resetActiveStep = (step) => {
    if (step === 0) {
      setSelectedIzometria(null);
      setSelectedRev(null);
      setSelectedSheet(null);
      setActiveStep(0);
    } else if (step === 1) {
      setSelectedSheet(null);
      setActiveStep(1);
    }
  };

  const nextStep = () => setActiveStep((current) => current + 1);
  const prevStep = () => setActiveStep((current) => current - 1);

  return (
    <div
      className='tracking-container'
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
    <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
      <Stepper
        className='mx-5'
        active={activeStep}
        onStepClick={(step) => step <= activeStep && resetActiveStep(step)}
        styles={{ stepDescription: { "fontSize": 16, "fontWeight": "bold", "color": "black" } }}
        sx={{ height: '100%', display: 'flex', flexDirection: 'column'}}
        allowStepSelect={(step) => {
          if (step === 0) return true;
          if (step === 1) return selectedIzometria !== null;
          if (step === 2) return selectedIzometria !== null && selectedSheet !== null;
          return false;
        }}
      >
        <Stepper.Step label="Izometria" description={selectedIzometria ? selectedIzometria.name : ""}>
          <Cards
            initialItems={izometrias}
            title="Válassz izometriát"
            onSelect={id => {
              const selected = izometrias.find(item => item.id === id);
              setSelectedIzometria(selected);
              nextStep();
            }}
          />
        </Stepper.Step>
        <Stepper.Step label="Lap" description={selectedSheet ? selectedSheet.name : ""}>
          <Cards
            initialItems={sheets}
            title="Válassz lapot"
            onSelect={id => {
              const selected = sheets.find(item => item.id === id);
              setSelectedSheet(selected);
              nextStep();
            }}
            selectedItem={selectedSheet && selectedSheet.id}
          />
        </Stepper.Step>
        <Stepper.Completed>
          {selectedSheet && (
            <TrackingTabs selectedSheet={selectedSheet} />
          )}
        </Stepper.Completed>
      </Stepper>
    </LoadingWrapper>
    </div>
  );
}

export default Tracking;
