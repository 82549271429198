import React, { useState, useRef, useEffect, useImperativeHandle, useMemo, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { MultiSelect, Text, Select, Modal, Button } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GlobalContext } from '../../Home/GlobalContext';
import LoadingWrapper from '../../Atoms/Loading';

const AgGridMultiSelectEditor = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || []);
  const refInput = useRef(null);

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    isPopup: () => true, // Ensures the dropdown is a popup extending beyond cell boundaries
  }));

  return (
    <div className="ag-cell-edit-wrapper">
      <MultiSelect
        ref={refInput}
        className="ag-cell-editor"
        style={{ width: '100%', height: '100%' }}
        data={props.options.map((option) => ({ label: option.name, value: option.id }))}
        value={value}
        onChange={setValue}
        searchable
        dropdownPosition="bottom"
        withinPortal={true}
      />
    </div>
  );
});

const Users = () => {
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [possibleNewUsers, setPossibleNewUsers] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
  const { projectIDGlobal, subcontractors } = useContext(GlobalContext);

  useEffect(() => {
    setLoading(true);

    const fetchProjectUsers = fetch(`${apiUrl}/api/users?project_id=${projectIDGlobal}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => response.json());

    const fetchAllUsers = fetch(`${apiUrl}/api/all_users`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => response.json());

    Promise.all([fetchProjectUsers, fetchAllUsers])
      .then(([projectUsersData, allUsersData]) => {
        setRowData(projectUsersData);
        setAllUsers(allUsersData);
      })
      .catch((error) => {
        alert('Failed to fetch users');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiUrl, projectIDGlobal]);

  useEffect(() => {
    if (allUsers.length > 0) {
      const existingUserIds = rowData.map(user => user.user_id);
      const availableUsers = allUsers.filter(user => !existingUserIds.includes(user.user_id));
      setPossibleNewUsers(availableUsers);
    }
  }, [allUsers, rowData]);

  const columnDefs = useMemo(() => [
    {
      headerName: '',
      maxWidth: 50,
      editable: false,
      cellRenderer: params => (
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <button onClick={() => deleteRow(params)} style={{ border: 'none', background: 'none' }}>
            <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
          </button>
        </div>
      )
    },
    { field: 'username', headerName: 'Felhasználónév', maxWidth: 200 },
    { field: 'admin', headerName: 'Adminisztrátor hozzáférés', editable: true, maxWidth: 250 },
    { field: 'data_entry', headerName: 'Adatbevitel hozzáférés', editable: true, maxWidth: 180 },
    { field: 'tracking', headerName: 'Nyomonkövetés hozzáférés', editable: true, maxWidth: 250 },
    { field: 'analytics', headerName: 'Analitika hozzáférés', editable: true, maxWidth: 180 },
    { field: 'workforce', headerName: 'Hegesztők hozzáférés', editable: true, maxWidth: 180 },
    {
      field: 'subcontractor_ids',
      headerName: 'Alvállalkozók',
      cellEditor: AgGridMultiSelectEditor,
      editable: true,
      cellEditorParams: {
        options: subcontractors,
      },
      valueFormatter: (params) => {
        const names = params.value.map((id) => {
          const subcontractor = subcontractors.find((s) => s.id === id);
          return subcontractor ? subcontractor.name : '';
        });
        return names.join(', ');
      },
      cellRenderer: (params) => {
        if (!params.value || params.value.length === 0) {
          return <Text size="sm" color="dimmed">Kattints alvállalkozók hozzáadásához</Text>;
        }
        const names = params.value.map((id) => {
          const subcontractor = subcontractors.find((s) => s.id === id);
          return subcontractor ? subcontractor.name : '';
        });
        return <Text size="md">{names.join(', ')}</Text>;
      },
      cellStyle: { overflow: 'visible', zIndex: 'auto' },
      flex: 1,
    },
  ], [subcontractors]);

  const onSaveButtonClick = () => {
    if (gridRef.current) {
      const gridApi = gridRef.current.api;
      const allRowData = [];
      gridApi.forEachNode((node) => allRowData.push(node.data));
      // Post to /api/users
      fetch(`${apiUrl}/api/users`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ project_id: projectIDGlobal, users: allRowData }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const handleAddUserClick = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleAddUserConfirm = (selectedUserId) => {
    const newUser = allUsers.find(user => user.user_id === selectedUserId);
    if (newUser) {
      setRowData([...rowData, {
        user_id: newUser.user_id,
        username: newUser.username,
        admin: false,
        data_entry: false,
        tracking: false,
        analytics: false,
        workforce: false,
        subcontractor_ids: [],
      }]);
    }
    setOpen(false);
  };

  const deleteRow = (params) => {
    params.api.applyTransaction({ remove: [params.data] });
    // Update rowData after deletion
    const gridApi = gridRef.current.api;
    const allRowData = [];
    gridApi.forEachNode((node) => allRowData.push(node.data));
    setRowData(allRowData);
  };

  return (
    <LoadingWrapper isLoading={loading}>
      <div className="users-container" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '10px', gap: '5px' }}>
          <Button onClick={onSaveButtonClick}>Mentés</Button>
          <Button onClick={handleAddUserClick}>Felhasználó hozzáadása a projekthez</Button>
        </div>
        <div className="ag-theme-alpine" style={{ flexGrow: 1, overflow: 'auto' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            defaultColDef={{
              resizable: true,
            }}
          />
        </div>

        <Modal opened={open} onClose={handleModalClose} title="Add User">
          <Select
            label="Select User"
            data={possibleNewUsers.map((user) => ({ label: user.username, value: user.user_id }))}
            onChange={handleAddUserConfirm}
            dropdownPosition="bottom"
            withinPortal={true}
          />
          <Button
            variant="filled"
            color="blue"
            onClick={() => handleAddUserConfirm(document.querySelector('.mantine-Select-input').value)}
            style={{ marginTop: '20px' }}
          >
            Confirm
          </Button>
        </Modal>
      </div>
    </LoadingWrapper>
  );
};

export default Users;
