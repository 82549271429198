import React, { useState, useEffect, useContext } from 'react';
import { Stepper, Group, Button, Loader, Modal } from '@mantine/core';
import { AuthContext } from '../../Home/AuthContext';
import { GlobalContext } from '../../Home/GlobalContext';
import Cards from '../../Atoms/Cards';
import RevisonCard from './RevisionCard';
import LoadingWrapper from '../../Atoms/Loading';
import AddIzometriaForm from './AddIzometriaForm';
import AddRevForm from './AddRevForm';
import AddSheetForm from './AddSheetForm';
import DataEntryTabs from './DataEntryTabs';

const DataEntry = () => {
  const { user } = useContext(AuthContext);
  const { projectNameGlobal, projectIDGlobal } = useContext(GlobalContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [loading, setLoading] = useState(true);

  const [activeStep, setActiveStep] = useState(0);
  const [izometrias, setIzometrias] = useState([]);
  const [selectedIzometria, setSelectedIzometria] = useState(null);
  const [revs, setRevs] = useState([]);
  const [selectedRev, setSelectedRev] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(null);
  const [showIzometriaModal, setShowIzometriaModal] = useState(false);
  const [showRevModal, setShowRevModal] = useState(false);
  const [showSheetModal, setShowSheetModal] = useState(false);

  useEffect(() => {
    // Fetch Izometrias
    fetch(`${apiUrl}/api/izometrias?project_id=${projectIDGlobal}`)
      .then(response => response.json())
      .then(data => {
        setIzometrias(data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setRevs([]);
    if (selectedIzometria) {
      // Fetch Revisions for the selected Izometria
      fetch(`${apiUrl}/api/revs?izometria_id=${selectedIzometria.id}`)
        .then(response => response.json())
        .then(data => {
          setRevs(data);
          setLoading(false);
        });
    }
  }, [selectedIzometria]);

  useEffect(() => {
    setSheets([]);
    if (selectedRev) {
      // Fetch Sheets for the selected Revision
      fetch(`${apiUrl}/api/sheets?rev_id=${selectedRev.id}`)
        .then(response => response.json())
        .then(data => {
          setSheets(data);
          setLoading(false);
        });
    }
  }, [selectedRev]);

  const resetActiveStep = (step) => {
    if (step === 0) {
      setSelectedIzometria(null);
      setSelectedRev(null);
      setSelectedSheet(null);
      setActiveStep(0);
    } else if (step === 1) {
      setSelectedRev(null);
      setSelectedSheet(null);
      setActiveStep(1);
    } else if (step === 2) {
      setSelectedSheet(null);
      setActiveStep(2);
    }
  };

  const onSetActive = (rev_id) => {
    setLoading(true);

    fetch(`${apiUrl}/api/revs/set_active?rev_id=${rev_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ rev_id }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to set revision active');
        }
        return response.json();
      })
      .then(data => {
        setRevs(data);
        setLoading(false);
      });
  };

  const nextStep = () => setActiveStep((current) => current + 1);
  const prevStep = () => setActiveStep((current) => current - 1);

  return (
    <div
      className='data-entry-container'
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
        <Stepper
          className='mx-5'
          active={activeStep}
          onStepClick={(step) => step <= activeStep && resetActiveStep(step)}
          styles={{ stepDescription: { fontSize: 16, fontWeight: 'bold', color: 'black' }, height: '100%' }}
          sx={{ height: '100%', display: 'flex', flexDirection: 'column'}}
          allowStepSelect={(step) => {
            if (step === 0) return true;
            if (step === 1) return selectedIzometria !== null;
            if (step === 2) return selectedIzometria !== null && selectedRev !== null;
            return false;
          }}
        >
          <Stepper.Step
            label="Izometria"
            description={selectedIzometria ? selectedIzometria.name : ""}
            >
            <Cards
              initialItems={izometrias}
              title="Válassz izometriát"
              onSelect={id => {
                const selected = izometrias.find(item => item.id === id);
                setSelectedIzometria(selected);
                nextStep();
              }}
              onAdd={() => setShowIzometriaModal(true)}
              onRename={(id, name) => {
                // ping the API to rename the item
                fetch(`${apiUrl}/api/rename_izometria?izometria_id=${id}&name=${name}`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ name }),
                })
                  .then(response => {
                    if (!response.ok) {
                      throw new Error('Failed to rename izometria');
                    }
                    return response.json();
                  });
              }}
              onDelete={(id) => {
                fetch(`${apiUrl}/api/delete_izometria?izometria_id=${id}`, {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ id }),
                })
                  .then(response => {
                    if (!response.ok) {
                      throw new Error('Failed to delete izometria');
                    }
                    return response.json();
                  });
              }}
            />
          </Stepper.Step>
          <Stepper.Step
            label="Revízió"
            description={selectedRev ? selectedRev.name : ""}
          >
            <div style={{ flex: 1 }}>
              <Cards
                initialItems={revs}
                title="Válassz revíziót"
                onSelect={id => {
                  const selected = revs.find(item => item.id === id);
                  setSelectedRev(selected);
                  nextStep();
                }}
                onAdd={() => {
                  const updatedRevs = revs.map(rev => {
                    return { ...rev, active: false };
                  });
                  setRevs(updatedRevs);
                  setShowRevModal(true);
                }}
                selectedItem={selectedRev && selectedRev.id}
                Card={RevisonCard}
                customCardProps={{ onSetActive: onSetActive }}
                onRename={(id, name) => {
                  // ping the API to rename the item
                  fetch(`${apiUrl}/api/rename_rev?rev_id=${id}&name=${name}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name }),
                  })
                    .then(response => {
                      if (!response.ok) {
                        throw new Error('Failed to rename rev');
                      }
                      return response.json();
                    });
                }}
                onDelete={(id) => {
                  fetch(`${apiUrl}/api/delete_rev?rev_id=${id}`, {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id }),
                  })
                    .then(response => {
                      if (!response.ok) {
                        throw new Error('Failed to delete rev');
                      }
                      return response.json();
                    });
                }}
              />
            </div>
          </Stepper.Step>
          <Stepper.Step
            label="Lap"
            description={selectedSheet ? selectedSheet.name : ""}
          >
              <Cards
                initialItems={sheets}
                title="Válassz lapot"
                onSelect={id => {
                  const selected = sheets.find(item => item.id === id);
                  setSelectedSheet(selected);
                  nextStep();
                }}
                onAdd={() => setShowSheetModal(true)}
                selectedItem={selectedSheet && selectedSheet.id}
                onRename={(id, name) => {
                  // ping the API to rename the item
                  fetch(`${apiUrl}/api/rename_sheet?sheet_id=${id}&name=${name}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name }),
                  })
                    .then(response => {
                      if (!response.ok) {
                        throw new Error('Failed to rename rev');
                      }
                      return response.json();
                    });
                }}
                onDelete={(id) => {
                  fetch(`${apiUrl}/api/delete_sheet?sheet_id=${id}`, {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id }),
                  })
                    .then(response => {
                      if (!response.ok) {
                        throw new Error('Failed to delete rev');
                      }
                      return response.json();
                    });
                }}
              />
          </Stepper.Step>
          <Stepper.Completed>
            {selectedSheet && (
              <DataEntryTabs selectedSheet={selectedSheet} />
            )}
          </Stepper.Completed>
        </Stepper>
        <Modal opened={showIzometriaModal} onClose={() => setShowIzometriaModal(false)}>
          <AddIzometriaForm
            izometrias={izometrias}
            setIzometrias={setIzometrias}
            setShowIzometriaModal={setShowIzometriaModal}
          />
        </Modal>
        <Modal opened={showRevModal} onClose={() => setShowRevModal(false)}>
          <AddRevForm
            revs={revs}
            setRevs={setRevs}
            selectedIzometria={selectedIzometria}
            setShowRevModal={setShowRevModal}
          />
        </Modal>
        <Modal opened={showSheetModal} onClose={() => setShowSheetModal(false)}>
          <AddSheetForm
            sheets={sheets}
            setSheets={setSheets}
            selectedRev={selectedRev}
            setShowSheetModal={setShowSheetModal}
          />
        </Modal>
      </LoadingWrapper>
    </div>
  );
}

export default DataEntry;
