import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // useLocation to get current URL path
import { isMobile } from 'react-device-detect';

const MobileRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();  // Get the current URL path

  useEffect(() => {
    console.log('MobileRedirect component');
    console.log(location.pathname);  // Log the current URL path
    console.log(isMobile);  // Log whether the user is on a mobile device
    // log window size
    console.log(window.innerWidth);
    console.log(window.innerHeight);
    // Only perform the redirect if the user is on the root path
    if (location.pathname === '/') {
      if (isMobile) {
        navigate('/mobile');  // Redirect to the mobile page
      } else {
        navigate('/project-selection');  // Redirect to the project selection page
      }
    }
  }, [location, navigate]);

  return null; // This component doesn't render anything
};

export default MobileRedirect;
