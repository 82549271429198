import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import {
    Group,
    Button,
    Text,
    HoverCard,
    ThemeIcon,
    useMantineTheme,
} from '@mantine/core';
import { IconUser, IconLogout, IconLogin } from '@tabler/icons-react';

const Navbar = () => {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const theme = useMantineTheme();
    
    const handleLogout = () => {
        logout();
        navigate('/login');
    }

    const handleLogin = () => {
        navigate('/login');
    }

    return (
        <header style={{ backgroundColor: '#16122f', padding: '10px' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Text size="xl" style={{ fontFamily: 'Roboto, sans-serif', fontWeight: '700', color: '#ffffff' }}>
                    CalcTracker
                </Text>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                    }}
                >
                    {user ? (
                        <>
                            <Button onClick={() => navigate('/')}>Projektek</Button>
                            <HoverCard width={180} position="bottom" radius="md" shadow="md">
                                <HoverCard.Target>
                                    <Button leftIcon={<IconUser />}>
                                        {user.username}
                                    </Button>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <Button onClick={handleLogout} fullWidth leftIcon={<IconLogout />} color={theme.colors.red[6]}>
                                        Kijelentkezés
                                    </Button>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        </>
                    ) : (
                        <Button onClick={handleLogin} leftIcon={<IconLogin />}>
                            Bejelentkezés
                        </Button>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Navbar;
