import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faClone } from '@fortawesome/free-solid-svg-icons';
import { Button, Badge } from '@mantine/core';
import LoadingWrapper from '../../../Atoms/Loading';
import { Loader } from '@mantine/core';
import * as moment from 'moment';
import AuthContext from '../../../Home/AuthContext';
import { GlobalContext } from '../../../Home/GlobalContext';
import AgGridSingleSelectEditor from '../../../../util/AgGridSingleSelectEditor';
import { filterWeldersOptions } from '../../../../util/weldChecks';
import dayjs from 'dayjs';

const HegesztesTrackingTable = ({ selectedSheet }) => {
    const [rowData, setRowData] = useState([]);
    const [origData, setOrigData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [workers, setWorkers] = useState([]);
    const [wpsData, setWpsData] = useState([]);
    const [materialsData, setMaterialsData] = useState([]);
    const [material, setMaterial] = useState('');
    const [qualifications, setQualifications] = useState([]);



    const { user } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const { projectIDGlobal } = useContext(GlobalContext);

    const gridRef = useRef();

    useEffect(() => {
        setLoading(true);

        fetch(`${apiUrl}/api/get_hegesztes_tracking?sheet_id=${selectedSheet.id}`) 
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                parsedData.sort((a, b) => a.sorszam - b.sorszam);
                parsedData.forEach((row) => {
                    row.date = row.date ? moment(row.date).toDate() : null;
                    row.is_done = !!row.progress_id;
                    row.orig_progress_id = row.progress_id;
                    row.modified = false;
                });
                console.log('parsedData: ', parsedData);
                setRowData(parsedData);
                setOrigData(JSON.parse(JSON.stringify(parsedData)));
                setLoading(false);
            })
            .catch(() => setLoading(false));
        
        fetch(`${apiUrl}/api/update_sheet?sheet_id=${selectedSheet.id}`)
            .then(response => response.json())
            .then(data => {
                setMaterial(data.material);
            });


    }, [selectedSheet.id]);

    useEffect(() => {

        fetch(`${apiUrl}/api/workforce?project_id=${projectIDGlobal}&user_id=${user.userId}`)
            .then(response => response.json())
            .then(data => JSON.parse(data.filtered_workers))
            .then(data => {
                data = data.map(worker => ({ value: worker.id, label: worker.name + '|' + worker.tag }));
                setWorkers(data);
                }
            )

        fetch(`${apiUrl}/api/wps?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                console.log('WPS data:', parsedData);
                setWpsData(parsedData);
                setLoading(false);
            })
        fetch(`${apiUrl}/api/materials`)
            .then(response => response.json())
            .then(data => setMaterialsData(data));

        fetch(`${apiUrl}/api/qualifications?project_id=${projectIDGlobal}&user_id=${user.userId}`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                //cast valid dates to dayjs objects
                parsedData.forEach(row => {
                    row.valid_from = dayjs(row.valid_from);
                    row.valid_to = dayjs(row.valid_to);
                });
                setQualifications(parsedData);
            });
    }, [projectIDGlobal, user.userId]);
        
    
    const columnDefs = useMemo(() => [
        {
            headerName: 'Mentett',
            field: 'modified',
            cellRenderer: (params) => (params.value == true ? <Badge color='red'>Nem</Badge> : <Badge color='green'>Igen</Badge>)
        },
        {
            headerName: "Elkészült",
            field: "is_done",
            editable: true,
            cellDataType: 'boolean',
        },
        { headerName: "Sorszám", field: "sorszam" },
        { headerName: "Típus", field: "tipus" },
        { headerName: "D", field: "D" },
        { headerName: "v", field: "v" },
        { headerName: "Munkaóra", field: "munkaora" },
        {
          headerName: "Elkészülés dátuma",
          field: "date", 
          editable: (params) => params.data.is_done,
          cellDataType: 'date',
        },
        {
            field: 'done_by',
            headerName: 'Készítette',
            editable: (params) => params.data.is_done,
            cellEditor: AgGridSingleSelectEditor, // Use the custom single select editor
            cellEditorParams: (params) => {
                const filtered_workers = filterWeldersOptions(params.data, material, wpsData, workers, qualifications, materialsData)
                return {
                    options: filtered_workers,
                };
            },
            valueFormatter: (params) => {
              // Format the value to show the label of the selected worker
              return workers.find(worker => worker.value === params.value)?.label || '';
            },
            filterValueGetter: (params) => {
              // Return the formatted label for filtering purposes
              return workers.find(worker => worker.value === params.data.done_by)?.label || '';
            },
            filter: 'agTextColumnFilter', // Use a text filter for matching the formatted label
            valueGetter: (params) => {
              // Get the value of 'done_by'
              return params.data.done_by;
            },
            valueSetter: (params) => {
              // Validate and set the value if it's a valid worker
              const selectedValue = params.newValue;
              if (workers.find(worker => worker.value === selectedValue)) {
                params.data.done_by = selectedValue;
                return true;
              }
              return false;
            },
            cellStyle: { overflow: 'visible', zIndex: 'auto' },
            flex: 1,
            suppressKeyboardEvent: (params) => {
              if (!params.editing) {
                return false;
              }
              return true;
            },
          },
        { headerName: 'Progress ID', field: 'progress_id', hide: true },
      ], [workers]);


    const saveData = () => {
        const rowData = [];
        const gridApi = gridRef.current.api;
        gridApi.forEachNode(node => rowData.push(node.data));
        setLoading(true);
        let requestBody = {
            data: rowData,
            user_id: user.userId
        };

        console.log("requestBody: ", requestBody);

        fetch(`${apiUrl}/api/save_hegesztes_tracking`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(() => {
                rowData.forEach((row) => {
                    row.modified = false;
                });
            })
            .then(() => {
                setOrigData(JSON.parse(JSON.stringify(rowData)));
                setLoading(false); // Set loading to false if fetch succeeds
            })
            .catch(error => {
                console.log('There was a problem with the fetch operation:', error.message);
                setLoading(false); // Set loading to false if fetch fails
            });
    }

    const onCellValueChanged = (params) => {
        console.log('params:', params);

        const id = params.data.id;
        console.log('id:', id);
        const originalRow = origData.find((row) => row.id === id);
        console.log('originalRow:', originalRow);
        console.log('params.data:', params.data);
        //compare the original row with the modified row but ignore the modified field
        const isModified = Object.keys(params.data).some((key) => key !== 'modified' && params.data[key] !== originalRow[key]);
        console.log('isModified:', isModified);
        const rowNode = params.api.getRowNode(params.node.id);
        if (isModified) {
            if (rowNode) {
                console.log('Modified row:', rowNode.data);
                rowNode.setDataValue('modified', true);
                rowNode.setDataValue('progress_id', null);
            };
        } else {
            if (rowNode) {
                console.log('Not modified row:', rowNode.data);
                rowNode.setDataValue('modified', false);
                rowNode.setDataValue('progress_id', rowNode.data.orig_progress_id);
            };
        }

        if (params.colDef.field === 'is_done'){
            if (params.data.is_done) {
                const rowNode = params.api.getRowNode(params.node.id);
                if (rowNode) {
                    rowNode.setDataValue('date', moment().toDate());
                    console.log('Date set to:', moment().toDate());
                } else {
                    console.error('Row node not found');
                }
            } else {
                const rowNode = params.api.getRowNode(params.node.id);
                if (rowNode) {
                    rowNode.setDataValue('date', null);
                    console.log('Date reset to null');
                } else {
                    console.error('Row node not found');
                }
            }
        }
    }

    return (
        <>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                  }
                `}
            </style>

            <Button
                onClick={saveData}
                style={{ padding: '10px', marginTop: '10px',marginBottom: '10px', marginRight: '10px'}}
            >
                Mentés
            </Button>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>  
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        stopEditingWhenCellsLoseFocus={true}
                        gridOptions={
                            {
                                singleClickEdit: true
                            }
                        }
                        defaultColDef={{
                            flex: 1,
                            cellStyle: () => ({
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }),
                            minWidth: 100,
                          }}
                        onCellValueChanged={onCellValueChanged}
                    />
                </LoadingWrapper>
            </div>
        </>
    );
};

export default HegesztesTrackingTable;
